import { createSlice } from "@reduxjs/toolkit";

import { convertFileToSFDT } from "../../actions/general/convertFileToSFDT";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  searchLoading: false,
  selectedData: {},
};

const GeneralSlice = createSlice({
  name: "compose",
  initialState,
  reducers: {
    updateSelectedData(state, action) {
      state.selectedData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(convertFileToSFDT.pending, (state) => {
      state.isFetching = true;
      state.searchLoading = true;
    });
    builder.addCase(convertFileToSFDT.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isError = false;
      state.isSuccess = true;
      state.searchLoading = false;
      state.errorMessage = "";
      state.selectedData = {};
    });
    builder.addCase(convertFileToSFDT.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.searchLoading = false;
    });
  },
});

export const { updateSelectedData } = GeneralSlice.actions;

export default GeneralSlice.reducer;
