import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { TbWorldWww } from "react-icons/tb";

import Loader from "../../../loader";
import GenerateDocument from "../GenerateDocument";

import {
  generateUniqueId,
  getCurrentDateTimeUTC,
} from "../../../../../utils/constant";
import { sendTransformData } from "../../../../../redux/slices/transform/transformSlice";
import { useVerifyWebMutation } from "../../../../../redux/slices/transform/transformApiSlice";

import styles from "./index.module.css";
import DownloaderComponent from "../../DownloaderComponent/DownloaderComponent";
import { selectCurrentUser } from "../../../../../redux/slices/auth/authSlice";

const FromInternet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(selectCurrentUser);

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [data, setData] = useState({});
  const [type, setType] = useState("");
  const [instructions, setInstructions] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [verifyWeb, { isLoading: verifying }] = useVerifyWebMutation();

  const onClickNext = async () => {
    if (isSuccess) {
      setIsSuccess(false);
      setPage((prev) => prev + 1);
      return;
    }

    if (search.length <= 0) {
      return;
    }

    const initialTransactionId = generateUniqueId();
    const obj = {
      transactionId: initialTransactionId,
      userid: userData?.ped_user_id,
      url: search,
      requesttimeinUTC: getCurrentDateTimeUTC(),
    };

    try {
      const response = await verifyWeb(obj).unwrap();
      if (response) {
        setData(response);
        setIsError(false);
        setIsSuccess(true);
      } else {
        setIsError(true);
        setIsSuccess(false);
        toast.error("Please try again later");
      }
    } catch (error) {
      setIsError(true);
      setIsSuccess(false);
      console.error("Error", error);
      toast.error("Please try again later");
    }
  };

  const generateDocument = async () => {
    if (type.length <= 0) {
      toast.error("Please select a rendering type");
      return;
    }

    const obj = {
      userid: userData?.ped_user_id,
      transactionId: data.transactionId,
      renderingType: type,
      instruction: instructions,
      url: search,
      requesttimeinUTC: getCurrentDateTimeUTC(),
    };

    setPage((page) => page + 1);
    // navigate("/notifications");
    dispatch(sendTransformData({ type: "web2Doc", obj }));
  };

  const DisplayPage = (pageNo) => {
    switch (pageNo) {
      case 0:
        return (
          <ToDocumentInput
            search={search}
            setSearch={setSearch}
            onClickNext={onClickNext}
            isError={isError}
            isSuccess={isSuccess}
          />
        );
      case 1:
        return (
          <GenerateDocument
            setType={setType}
            instructions={instructions}
            setInstructions={setInstructions}
          />
        );
      case 2:
        return <DownloaderComponent />;
      default:
        return "Page Not Found";
    }
  };

  return (
    <div>
      {verifying && <Loader message="Verifying URL" />}

      {DisplayPage(page)}
      <div className={styles.nextButtonContainer}>
        {page === 0 && (
          <button onClick={onClickNext} className={styles.nextButton}>
            Next
          </button>
        )}
        {page > 0 && (
          <button onClick={generateDocument} className={styles.generateButton}>
            Generate Document
          </button>
        )}
      </div>
    </div>
  );
};

export default FromInternet;

const ToDocumentInput = ({
  search,
  setSearch,
  onClickNext,
  isError,
  isSuccess,
}) => {
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  return (
    <div>
      <p className={styles.paragraph}>
        Please provide a website link in the following box. It's important to
        note that our Al models are proficient at processing text exclusively.
        Any additional content such as images or videos will not be considered.
      </p>
      <p className={styles.paragraph}>
        Additionally, please ensure that no login is required to access the
        website screen. If login is necessary, we recommend copying the text
        from the website into the Text section for transformation purposes.
      </p>
      <div>
        <div>
          <div className={styles.inputField}>
            <input
              type="text"
              value={search}
              placeholder={"Please enter a valid URL"}
              className={styles.input}
              onChange={(e) => handleSearch(e)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  onClickNext();
                }
              }}
            />
            {/* <div className={styles.uploadIconContainer} onClick={onClickNext}>
              <TbWorldWww className={styles.uploadIcon} />
            </div> */}
          </div>
        </div>
        <div className={styles.infoContainer}>
          {isError && (
            <div className={styles.errorText}>
              Provided website link could not be browsed successfully. Please
              recheck the link.
            </div>
          )}
          {isSuccess && (
            <div className={styles.successText}>
              The website link has been successfully analyzed. It matches all
              criteria of document trnaformation. Please click Next button below
              to share some configuration details.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
