import React from "react";
import styles from "./index.module.css";

function Notification() {
  return (
    <>
      <div className={styles.top_container}>
        <h1>Pioneer Ed Tech | Notifications</h1>
        <p>
          This screen is under active development. Please check again later.
        </p>
      </div>
    </>
  );
}

export default Notification;
