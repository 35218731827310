import Select from "react-select";
import { useEffect } from "react";

import styles from "./index.module.css";

const slideCountOptions = [
  { value: "Default", label: "Default" },
  { value: "5-10", label: "5-10" },
  { value: "10-15", label: "10-15" },
];

const contentTypeOptions = [
  { value: "Summary", label: "Summary" },
  { value: "Full Text", label: "Full Text" },
];

const formatsOptions = [
  { value: "Text Only", label: "Text Only" },
  { value: "List with headings", label: "List with headings" },
  { value: "List with text", label: "List with text" },
];

const defaultSlideCount = slideCountOptions[0];
const defaultContentType = contentTypeOptions[1];
const defaultFormat = formatsOptions[0];

const GeneratePresentation = ({
  notes,
  setNotes,
  setSlideCount,
  setContentType,
  setFormat,
}) => {
  const toggle = () => setNotes((notes) => !notes);

  useEffect(() => {
    setSlideCount(slideCountOptions[0].value);
    setContentType(contentTypeOptions[1].value);
    setFormat(formatsOptions[0].value);
  }, [setSlideCount, setContentType, setFormat]);

  return (
    <div className={styles.documentGeneration}>
      <p className={styles.paragraph}>
        Kindly share the configuration details needed to generate the
        presentation. We will use the provided inputs to create the most optimal
        presentation.
      </p>
      <div className={styles.documentTypeContainer}>
        <div className={styles.selectDocumentType}>
          <div className={styles.selectDocumentGroup}>
            <label className={styles.selectDocumentLabel}>Side count</label>
            <Select
              options={slideCountOptions}
              defaultValue={defaultSlideCount}
              onChange={(e) => setSlideCount(e.value)}
            />
          </div>
          <div className={styles.selectDocumentGroup}>
            <label className={styles.selectDocumentLabel}>Format</label>
            <Select
              options={formatsOptions}
              defaultValue={defaultFormat}
              onChange={(e) => setFormat(e.value)}
            />
          </div>
        </div>
        <div className={styles.selectDocumentType}>
          <div className={styles.selectDocumentGroup}>
            <label className={styles.selectDocumentLabel}>Content Type</label>
            <Select
              options={contentTypeOptions}
              defaultValue={defaultContentType}
              onChange={(e) => setContentType(e.value)}
            />
          </div>
          <div className={styles.checkboxAnimate}>
            <label style={{ textAlign: "center" }}>
              <input
                type="checkbox"
                name="check"
                checked={notes}
                onChange={toggle}
                className={styles.input}
              />
              <span className={styles.inputCheck}></span>I like to generate
              notes for each slides
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratePresentation;
