import { useState, useCallback } from "react";
import { generateUniqueId } from "../../utils/constant";

export const ApiStatus = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
};

export const useApiCall = (apiFunctions, dispatch, clearTransformData) => {
  const [isCalling, setIsCalling] = useState(false);
  const [status, setStatus] = useState(ApiStatus.NOT_STARTED);
  const [responseLink, setResponseLink] = useState(null);
  const [quizID, setQuizID] = useState(null);

  const callApi = useCallback(
    async (type, initialArgs) => {
      if (isCalling) return;
      setIsCalling(true);
      setStatus(ApiStatus.IN_PROGRESS);

      const attemptApiCall = async (retryCount, priorTranIds) => {
        const newTransactionId = generateUniqueId();
        const args = {
          ...initialArgs,
          transactionId: newTransactionId,
          priorTranIds: priorTranIds.join(", "),
        };

        try {
          const response = await apiFunctions[type](args).unwrap();

          setStatus(ApiStatus.COMPLETED);
          setQuizID(String(response?.QuizFileId));
          console.log("line33 resoponse", response);
          setResponseLink(response?.Response);
          dispatch(clearTransformData());
        } catch (error) {
          console.error("API call failed:", error);
          if (retryCount < 6) {
            setTimeout(() => {
              attemptApiCall(retryCount + 1, [
                ...priorTranIds,
                newTransactionId,
              ]);
            }, 15000);
          } else {
            setStatus(ApiStatus.FAILED);
            dispatch(clearTransformData());
          }
        } finally {
          setIsCalling(false);
        }
      };

      attemptApiCall(0, []);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [apiFunctions, dispatch]
  );

  return [callApi, status, responseLink, quizID];
};
