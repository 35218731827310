import { createSlice } from "@reduxjs/toolkit";

const transformSlice = createSlice({
  name: "transform",
  initialState: {
    transformData: {},
  },
  reducers: {
    sendTransformData: (state, action) => {
      state.transformData = action.payload;
    },
    clearTransformData: (state) => {
      state.transformData = {};
    },
  },
});

export const { sendTransformData, clearTransformData } = transformSlice.actions;

export default transformSlice.reducer;

export const TransformData = (state) => state.transform.transformData;
