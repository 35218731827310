import React from "react";

export const HomeIcon = () => {
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_13_3579)">
      <path
        d="M12 5.69L17 10.19V18H15V12H9V18H7V10.19L12 5.69V5.69ZM12 3L2 12H5V20H11V14H13V20H19V12H22L12 3Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_13_3579">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>;
};
