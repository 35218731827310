import { X } from 'lucide-react';

import styles from "./index.module.css";

const SuccessBanner = ({ link, setOpenSuccessLink }) => {
  console.log("link", link);
  const download = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = link;
    downloadLink.setAttribute("text", true);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className={styles.success_banner}>
      <div className={styles.success_banner_container}>
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <CheckCircleIcon className={styles.icon} />
            <div>
              Document has been generated successfully. Please click on this
              <span
                onClick={download}
                style={{
                  margin: "0 5px 0 5px",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                link
              </span>
              to download the document.
            </div>
          </div>
          <button
            className={`${styles.ghostButton} ${styles.textWhite}`}
            onClick={() => setOpenSuccessLink(false)}
          >
            <X className={styles.buttonIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessBanner;

function CheckCircleIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
      <polyline points="22 4 12 14.01 9 11.01" />
    </svg>
  );
}

function PanelTopCloseIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
      <line x1="3" x2="21" y1="9" y2="9" />
      <path d="m9 16 3-3 3 3" />
    </svg>
  );
}
