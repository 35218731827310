export const getParseData = (data) => {
    console.log(data)
    try{
        return JSON.parse(data);
    }catch(e){
        console.log(e);
        return null
    }
}

export const getComposeFormattedData = (strData) => {
  const filteredData = getParseData(strData?.Response) || {};

  const formattedData = Object.entries(filteredData).map(
    ([title, content], index) => ({
      id: index + 1,
      title,
      content,
    })
  );

  console.log("formattedData", formattedData);

  return formattedData;
};
