import { X } from "lucide-react";

import styles from "./index.module.css";

const WarnBanner = ({ setOpenErrorLink }) => {
  return (
    <div className={styles.success_banner}>
      <div className={styles.success_banner_container}>
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <X className={styles.icon} />
            <div>
              Ohh! Some problems occured while generating the document. Please
              try again after some time.
            </div>
          </div>
          <button
            className={`${styles.ghostButton} ${styles.textWhite}`}
            onClick={() => setOpenErrorLink(false)}
          >
            <X className={styles.buttonIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default WarnBanner;
