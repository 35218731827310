import Select from "react-select";

import styles from "./index.module.css";

const options = [
  { value: "Critical analysis", label: "Critical analysis" },
  { value: "Online References", label: "Online References" },
  { value: "Executive Summary", label: "Executive Summary" },
  {
    value: "Subjective Question and Answers",
    label: "Subjective Question and Answers",
  },
  {
    value: "Objective Question and Answers",
    label: "Objective Question and Answers",
  },
  { value: "Improvement Suggestions", label: "Improvement Suggestions" },
  { value: "Study guide", label: "Study guide" },
  { value: "Seminar discussion", label: "Seminar discussion" },
  { value: "Research proposals", label: "Research proposals" },
  { value: "Reflection paper", label: "Reflection paper" },
  {
    value: "Reflection paper instruction",
    label: "Reflection paper instruction",
  },
  { value: "Essay expansion assignment", label: "Essay expansion assignment" },
  {
    value: "Critical analysis instruction",
    label: "Critical analysis instruction",
  },
  { value: "Annotated bibliography", label: "Annotated bibliography" },
];

const GenerateDocument = ({ setType, instructions, setInstructions }) => {
  const handleChange = (e) => {
    setInstructions(e.target.value);
  };

  return (
    <div className={styles.documentGeneration}>
      <p className={styles.paragraph}>
        Kindly share the configuration details needed to generate the document.
        We will use the provided inputs to create the most optimal document.
      </p>
      <div className={styles.documentTypeContainer}>
        <div className={styles.selectDocumentType}>
          <label className={styles.instructionsLabel}>Rendering Type</label>
          <Select options={options} onChange={(e) => setType(e.value)} />
        </div>
        {/* <div className={styles.documentInstructions}>
          <div className={styles.documentInstructionsText}>
            <span className={styles.highlight}>Description: </span> A document
            that outlines key concepts. terms, and themes from the essay, along
            with questions or prompts for further reflection. It may also
            include a glossary of terms and suggested readings for extended
            learning.
          </div>
          <div className={styles.documentInstructionsText}>
            <span className={styles.highlight}>Purpose: </span> To aid students
            in understanding and retaining the essay's main ideas and encourage
            self-directed exploration of the topic.
          </div>
        </div> */}
      </div>
      <div className={styles.instructionsInputContainer}>
        <label className={styles.instructionsLabel}>Formal Instructions</label>
        <textarea
          rows="3"
          value={instructions}
          className={styles.instructionsInput}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default GenerateDocument;
