import React, {useState, useEffect, useRef} from 'react';
import {Helmet} from "react-helmet";
import {Upload} from "lucide-react";
import Dropzone from "react-dropzone";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import myVideo from '../../video/video-clip.mp4';
import Loader from "../../components/shared/loader";
import {generateUniqueId, getCurrentDateTimeUTC} from "../../utils/constant";

import {sendTransformData} from "../../redux/slices/transform/transformSlice";
import {
    useVerifyDocumentMutation,
    useVerifyPPTMutation,
} from "../../redux/slices/transform/transformApiSlice";

import styles from "./index.module.css";
import DownloaderComponent from "../../components/shared/Transform/DownloaderComponent/DownloaderComponent";
import {selectCurrentUser} from "../../redux/slices/auth/authSlice";

const Video = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        // Autoplay the video when the component mounts
        if (videoRef.current) {
            videoRef.current.muted = true; // Mute the video to allow autoplay
            videoRef.current.play();
        }
    });
    const [isVideoGenerated, setVideoGenerated] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector(selectCurrentUser)

    const [fileName, setFileName] = useState("");
    const [data, setData] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);
    const [isDisable, setIsDisable] = useState(true);
    const [isError, setIsError] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [showDownloader, setShowDownloader] = useState(false);

    const [base64, setBase64] = useState("");
    const [fileExtension, setFileExtension] = useState("");

    const [fileError, setFileError] = useState(false);

    const [verifyDocument, {isLoading: verifyingDocument}] =
        useVerifyDocumentMutation();
    const [verifyPPT, {isLoading: verifyingPPT}] = useVerifyPPTMutation();
    const [videoGenerate, generatingVideo] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const handleUpload = async (acceptedFiles) => {
        const initialTransactionId = generateUniqueId();

        let obj = {
            userid: userData?.ped_user_id,
            transactionId: initialTransactionId,
            requesttimeinUTC: getCurrentDateTimeUTC(),
        };

        const filteredFiles = acceptedFiles.filter((file) => {
            const isDocument =
                (file.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    file.type ===
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation") &&
                file.size <= 1 * 1024 * 1024;

            return isDocument;
        });

        if (filteredFiles.length > 0) {
            const file = filteredFiles[0];
            setFileName(file.name);
            const extension = file.name.split(".").pop();
            setFileExtension(extension);

            try {
                const base64String = await getBase64(file);
                setBase64(base64String);
                setFileError(false);
                obj = {
                    ...obj,
                    fileName: file.name,
                    fileContentBase64: base64String,
                };

                let response;
                if (extension === "docx") {
                    response = await verifyDocument(obj).unwrap();
                } else if (extension === "pptx") {
                    response = await verifyPPT(obj).unwrap();
                }

                if (response) {
                    setData(response);
                    setIsError(false);
                    setIsSuccess(true);
                } else {
                    throw new Error("Invalid server response");
                }
            } catch (error) {
                //setIsError(true);
                setIsSuccess(false);
                console.error("Error:", error);
                toast.error("Please try again later");
            }
        } else {
            setFileName("");
            setFileError(true);
            console.log("File size/type does not meet the criteria.");
        }
    };

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result
                    .replace("data:", "")
                    .replace(/^.+,/, "");
                resolve(base64String);
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    }

    const generateVideo = async () => {
        if (isSuccess) {
            generatingVideo(true);
            const randomWaitTime = Math.floor(Math.random() * (10000 - 5000 + 1)) + 5000; // Random time between 5 to 10 seconds
            setTimeout(() => {
                generatingVideo(false);
                setShowVideo(true);
                if (videoRef.current) {
                    videoRef.current.muted = true; // Mute the video to allow autoplay
                    videoRef.current.play();
                }
            }, randomWaitTime);
            setIsDisable(false);
        }

        const obj = {
            userid: userData?.ped_user_id,
            transactionId: data.transactionId,
            fileName,
            fileContentBase64: base64,
            requesttimeinUTC: getCurrentDateTimeUTC(),
        };

        if (fileExtension === "docx") {
            // navigate("/notifications");
            setShowDownloader(true);
            dispatch(sendTransformData({type: "doc2Video", obj}));
        } else if (fileExtension === "pptx") {
            // navigate("/notifications");
            setShowDownloader(true);
            dispatch(sendTransformData({type: "ppt2Video", obj}));
        }
    };

    const acceptFormats = {
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
        "application/vnd.ms-powerpoint": [".ppt"],
    };

    return (
        <>
            <Helmet>
                <title>Pioneer Ed Tech | Transform | Video</title>
            </Helmet>
            {showNotification === true ? (
                <DownloaderComponent/>
            ) : (
                <div className={styles.container}>
                    {verifyingDocument && <Loader message="Verifying Document"/>}
                    {verifyingPPT && <Loader message="Verifying PPT"/>}
                    {videoGenerate && <Loader message="Generating Video from PPT"/>}

                    <h1 className={styles.title}>
                        Step into the world of transforming your documents and presentations
                        into learning videos. Unveil the potential of converting your text
                        into dynamic, interactive learning experiences. With ease, upload
                        your materials and witness the seamless creation of videos.
                    </h1>
                    <div className={styles.tabContainer}>
                        <div className={styles.tabContent}>
                            <div className={styles.innerContainer}>
                                <p className={styles.paragraph}>
                                    To ensure smooth processing, kindly note that files should not
                                    exceed 1 MB in size Supported file formats for upload include
                                    presentations (pptx)
                                </p>
                                <div>
                                    <div>
                                        <Dropzone
                                            multiple={false}
                                            accept={acceptFormats}
                                            onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}
                                        >
                                            {({getRootProps, getInputProps}) => (
                                                <div {...getRootProps()} className={styles.inputField}>
                                                    <input {...getInputProps()} />
                                                    <input
                                                        className={styles.input}
                                                        type="text"
                                                        value={fileName}
                                                        disabled
                                                    />
                                                    <div className={styles.uploadIconContainer}>
                                                        <Upload className={styles.uploadIcon}/>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                    <div className={styles.infoContainer}>
                                        {fileError && (
                                            <div className={styles.errorText}>
                                                File size/type does not meet the criteria.
                                            </div>
                                        )}
                                        {isError && (
                                            <div className={styles.errorText}>
                                                Sorry for the inconvenience. Please try again later.
                                            </div>
                                        )}
                                        {isSuccess && (
                                            <div className={styles.successText}>
                                                The uploaded file matches all criteria for video
                                                creation. We shall start processing processing now.
                                                Please click on the Generate button below.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.nextButtonContainer}>
                        {showVideo && (
                            <video ref={videoRef} width="600" controls>
                                <source src={myVideo} type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </div>
                    <div className={styles.nextButtonContainer}>
                        {isDisable &&
                        <button onClick={generateVideo} className={styles.generateButton}>
                            Generate Learning Video
                        </button>}
                    </div>
                </div>
            )}
        </>
    );
};

export default Video;
