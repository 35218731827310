import {Helmet} from "react-helmet";
import "./index.module.css";
import styles from "./index.module.css";
import { Upload} from "lucide-react";
import React, { useState, useEffect } from 'react';
import swapIcon from "../../Icons/swap.png";
import {Button} from "react-bootstrap";
import {toast} from "react-toastify";

export default function Component() {
    const [options, setOptions] = useState([]);
    const [selectOne, setSelectOne] = useState('');
    const [selectTwo, setSelectTwo] = useState('');
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const response = await fetch('https://restcountries.com/v3.1/all');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const languageSet = new Set();
                data.forEach(country => {
                    Object.values(country.languages || {}).forEach(language => languageSet.add(language));
                });
                setLanguages(Array.from(languageSet));
            } catch (error) {
                setError(error);
            }
        };

        fetchLanguages();
    }, []);
    const handleSelectOneChange = (e) => {
        const newValue = e.target.value;
        setSelectOne(newValue);
        toast.success("You have selected:"+newValue);
    };
    const handleSelectTwoChange = (e) => {
        const newValue = e.target.value;
        setSelectTwo(newValue);
        toast.success("You have selected:"+newValue);

    };

    const handleSwap = () => {
        if (selectOne && selectTwo) {
            const temp = selectOne;
            setSelectOne(selectTwo);
            setSelectTwo(temp);
        }
        if (selectOne === selectTwo && selectOne!=='' && selectTwo!=='') {
            toast.error("You have selected same language");
        }
        else if(!selectOne && !selectTwo){
            toast.error("Please select both language");
        }
        else if(!selectOne) {
            toast.error("Please select current PDF language");
        }
        else if(!selectTwo) {
            toast.error("Please select language to convert");
        }
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const translate = () =>{
        if (selectOne === selectTwo && selectOne!=='' && selectTwo!=='') {
            toast.error("Can't convert PDf please select different language");
        }
    }

    return (
        <div className="flex-row">
            <div className="flex-container">
                <div className="flex-item col-12">
                    <Helmet>
                        <title>Translate</title>
                    </Helmet>
                    <div key="1" className="mt-20 translate-container">
                        <h1 className="title">Translate your PDF documents in a snap! ✨</h1>
                        <div className="translate-info">

                        </div>
                    </div>
                </div>
                <div className="flex-item col-12">

                    <div className="mt-20  input-container">
                        <input id={"file"}
                               className={styles.input}
                               type="file"
                               placeholder="Upload PDF to translate"
                               value=""
                        />
                        <div className={styles.uploadIconContainer}>
                            <Upload className={styles.uploadIcon}/>
                        </div>
                    </div>

                </div>

                <div className="flex-item col-2">
                    <div className="mt-20 input-container">
                        <select id={"from-lang"} className={styles.input}
                                value={selectOne}
                                onChange={handleSelectOneChange}
                        >
                            <option value="" disabled selected>From Language</option>
                            {languages.map((language, index) => (
                                <option key={index} value={language}>{language}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="flex-item col-1 item-center ">
                    <div className="mt-20 input-container">
                        <button
                            onClick={handleSwap}
                            className={styles.swapBtn}
                            style={{}}
                        >
                            <img  className="rotate-on-mobile " src={swapIcon} alt="Swap Language" width={20} height={20}/>
                        </button>
                    </div>
                </div>
                <div className="flex-item col-2">
                    <div className="mt-20 input-container">
                        <select id={"to-lang"} className={styles.input}
                                value={selectTwo}
                                onChange={handleSelectTwoChange}
                        >
                            <option value="" disabled selected>To Language</option>
                            {languages.map((language, index) => (
                                <option key={index} value={language}>{language}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="flex-item col-2">
                    <div className="mt-20 input-container">
                        <Button className={styles.submitBtn} onClick={translate}>Translate</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
