import styles from './index.module.css';


export default function Component() {
  return (
    <div key="1" className={styles.container}>
      <div className={styles.closeButtonContainer}>
        <button aria-label="Close" className={styles.ghostButton}>
          <XIcon className={styles.closeIcon} />
        </button>
      </div>
      <div className={styles.spaceY4}>
        <p className={styles.textSmall}>
          Your work in the document editing section has not been saved. Without saving, your work may get lost.
        </p>
        <p className={`${styles.textSmall} ${styles.textSmallBold}`}>
          Are you sure you like to proceed?
        </p>
        <div className={styles.buttonGroup}>
          <button className={styles.outlineButton}>No</button>
          <button className={styles.standardButton}>Yes</button>
        </div>
      </div>
    </div>
  )
}

function XIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  )
}
