import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./index.module.css";
import {
  closeWarningMessage,
  documentIsNotSaved,
  textIsNotGenerated,
} from "../../../redux/slices/quickText/quickTextSlice";
import {
  closeListAccordion,
  closeOutlineAccordion,
  closeWordProcessor,
  updateCleanData,
  updateSelectedData,
  updateTopicOutlineData,
} from "../../../redux/slices/compose";

export default function SidebarProgress() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  // const state = useSelector((state) => state);

  const handleClick = () => {
    // let data = state?.compose?.screenDetails;
    // console.log("sides", data.sidebar);
    // console.log("state?.compose?", data);

    // if (data.sidebar) {
    //   navigate(data.route);
    // }

    dispatch(documentIsNotSaved());
    dispatch(textIsNotGenerated());
    dispatch(closeWarningMessage());

    // Reset Guided synthesis states
    dispatch(updateTopicOutlineData(""));
    dispatch(updateSelectedData({}));
    dispatch(closeListAccordion());
    dispatch(closeOutlineAccordion());
    dispatch(closeWordProcessor());

    // Clean Data global
    dispatch(updateCleanData(true))
  };

  return (
    <div className={styles.banner}>
      <div className={styles.banner_container}>
        <div key="1" className={styles.container}>
          <div className={styles.closeButtonContainer}>
            <button aria-label="Close" className={styles.ghostButton}>
              <XIcon
                className={styles.closeIcon}
                onClick={() => dispatch(closeWarningMessage())}
              />
            </button>
          </div>
          <div className={styles.spaceY4}>
            <p className={styles.textSmall}>
              Your work in the document editing section has not been saved.
              Without saving, your work may get lost.
            </p>
            <p className={`${styles.textSmall} ${styles.textSmallBold}`}>
              Are you sure you like to proceed?
            </p>
            <div className={styles.buttonGroup}>
              <button
                className={styles.outlineButton}
                onClick={() => dispatch(closeWarningMessage())}
              >
                No
              </button>
              <button className={styles.standardButton} onClick={handleClick}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  );
}
