import { Helmet } from "react-helmet";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../components/shared/loader";
import Editor from "../../components/shared/SyncFusionEditor";
import SyncfusionWordProcessor from "../../components/shared/SyncFusionWordProcessor/SyncfusionWordProcessor";

import { generateUniqueId, getCurrentDateTimeUTC } from "../../utils/constant";
import { getOutilneFromTopic } from "../../redux/actions/compose/getOutilneFromTopic";
import { getComposeSearchData } from "../../redux/actions/compose/getComposeSearchData";
import { getTextFromTopicOutline } from "../../redux/actions/compose/getTextFromTopicOutline";
import {
  TextGenrated,
  DocumentSaved,
  textIsGenerated,
  openWarningMessage,
  documentIsNotSaved,
  textIsNotGenerated,
} from "../../redux/slices/quickText/quickTextSlice";
import {
  closeListAccordion,
  closeOutlineAccordion,
  closeWordProcessor,
  updateListAccordionOpen,
  updateOutlineAccordionOpen,
  updateSelectedData,
  updateTopicOutlineData,
} from "../../redux/slices/compose";

import "./index.css";
import { selectCurrentUser } from "../../redux/slices/auth/authSlice";

const Compose = () => {
  const dispatch = useDispatch();
  const searchInputRef = useRef("");
  const wordProcessorRef = useRef(null);
  const [data, setData] = useState(null);
  const state = useSelector((state) => state);
  const textGenerated = useSelector(TextGenrated);
  const isDocumentSaved = useSelector(DocumentSaved);
  const userData = useSelector(selectCurrentUser);

  const handleContentChange = (args) => {
    dispatch(updateTopicOutlineData(args.value));
  };

  // console.log("isDocumentSaved", textGenerated, isDocumentSaved);

  const handleSearch = (searchValue) => {
    if (textGenerated && !isDocumentSaved) {
      dispatch(openWarningMessage());
      return;
    }

    if (textGenerated && isDocumentSaved) {
      dispatch(documentIsNotSaved());
      dispatch(textIsNotGenerated());
      dispatch(updateTopicOutlineData(""));
      dispatch(updateSelectedData({}));
      dispatch(closeListAccordion());
      dispatch(closeOutlineAccordion());
      dispatch(closeWordProcessor());
    }

    if (searchValue !== "") {
      const request = {
        transactionId: generateUniqueId(),
        userid: userData?.ped_user_id,
        role: "Academician",
        topic: searchValue,
        requesttimeinUTC: getCurrentDateTimeUTC(),
      };

      dispatch(getComposeSearchData(request));
    }
  };

  const handleGetOutlineFromTopic = () => {
    if (state?.compose?.selectedData?.content) {
      const request = {
        transactionId: generateUniqueId(),
        userid: userData?.ped_user_id,
        role: "Academician",
        topic: searchInputRef.current.value,
        summary: state?.compose?.selectedData?.content,
        requesttimeinUTC: getCurrentDateTimeUTC(),
      };

      dispatch(getOutilneFromTopic(request));
    }
  };

  const handleTextGenerateFromOutline = async () => {
    if (state?.compose?.topicOutline) {
      const request = {
        transactionId: generateUniqueId(),
        userid: userData?.ped_user_id,
        role: "Academician",
        topic: searchInputRef.current.value,
        summary: state?.compose?.topicOutline,
        outline: state?.compose?.topicOutline,
        requesttimeinUTC: getCurrentDateTimeUTC(),
      };

      const responseData = await dispatch(getTextFromTopicOutline(request));

      if (responseData) {
        setData(JSON.parse(responseData.payload));
        dispatch(textIsGenerated());
        return;
      }
    }
  };
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isInitialValueSet, setIsInitialValueSet] = useState(false);


  useEffect(() => {
    if (!isInitialValueSet) {
// Get the query string from the current URL
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

// Check if 'p' parameter is present and set it as the input value
      if (urlParams.has('query')) {
        const pValue = urlParams.get('query');
        setInputValue(pValue);
        setIsInitialValueSet(true); // Ensure we don't set it again
      }
    }
    setIsValid(inputValue.length > 3);
  }, [inputValue, isInitialValueSet]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      wordProcessorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [data]);

  // console.log("searchInputRef", searchInputRef.current.value);
  // console.log("state?.compose?.isWordProcessorVisible",state?.compose?.isWordProcessorVisible)
  // console.log("responseData", data);

  return (
      <>
        <Helmet>
          <title>Pioneer Ed Tech | Compose | Guided Text</title>
        </Helmet>
        <div className="compose-main-container">
          {state?.compose?.searchLoading && (
              <Loader message="Generating Perspectives" />
          )}
          {state?.compose?.outlineLoading && (
              <Loader message="Generating Outline" />
          )}
          {state?.compose?.textLoading && <Loader message="Generating Text" />}
          {state?.compose?.savingDocument && <Loader message="Saving Document" />}
          <div className="intruction-text">
            You have the freedom to create documents on any subject of your
            choice. Our advanced AI models are here to assist you in crafting
            engaging text-based documents swiftly.
            <br />
            <div className="intruction-gap" />
            Kindly enter your desired topic. Providing a well-defined topic will
            aid in precisely focusing our search. For instance, you might consider
            topics such as Recent advancements in AI, The effects of globalization
            in India post-COVID, or Economic disparities in India due to the
            digital divide.
          </div>
          <div className="mt-20 search-bar-container">
            <input
                type="text"
                ref={searchInputRef}
                placeholder="Please enter a topic"
                className="search-input"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleSearch(searchInputRef.current.value);
                  }
                }}
            />
            <button
                className="search-button"
                onClick={() => handleSearch(searchInputRef.current.value)}
            >
              <img
                  width={20}
                  height={20}
                  alt="Search Button"
                  src="https://static-00.iconduck.com/assets.00/search-icon-2044x2048-psdrpqwp.png"
              />
            </button>
          </div>

          {state?.compose?.isListAccordionVisible && (
              <div
                  onClick={() => {
                    dispatch(updateListAccordionOpen());
                  }}
                  className="mt-20 accordion-wrapper"
              >
                <div className="accordion-title-arrow">
                  <span>Perspectives</span>
                  <img
                      onClick={() => {
                        dispatch(updateListAccordionOpen());
                      }}
                      width={20}
                      height={20}
                      alt="Accordian"
                      src={"https://static.thenounproject.com/png/551749-200.png"}
                  />
                </div>
                {state?.compose?.isListAccordionOpen &&
                    state?.compose?.searchList?.length > 0 && (
                        <>
                          <div className="mt-20 intruction-text">
                            Kindly choose a viewpoint for your document. Examine the
                            title and summary within each tile to guide us in creating
                            the text according to your preference.
                          </div>
                          <div className="container-grid">
                            {state?.compose?.searchList.slice(0, 4).map((container) => (
                                <div
                                    onClick={(e) => {
                                      dispatch(updateSelectedData(container));
                                      e.stopPropagation();
                                    }}
                                    key={container.id}
                                    className={`container ${
                                        state?.compose?.selectedData?.id === container.id
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                  <h3 data-tooltip-id={`${container.id}-title`}>
                                    {container.title}
                                  </h3>
                                  <p data-tooltip-id={`${container.id}-content`}>
                                    {container.content}
                                  </p>
                                </div>
                            ))}
                          </div>
                          <div className="mt-20 generate-btn-wrapper">
                            <button
                                onClick={handleGetOutlineFromTopic}
                                className="generate-btn"
                            >
                              Generate Outline
                            </button>
                          </div>
                        </>
                    )}
              </div>
          )}
          {state?.compose?.isOutlineAccordionVisible && (
              <div className="mt-20 accordion-wrapper">
                <div
                    className="accordion-title-arrow"
                    onClick={() => {
                      dispatch(updateOutlineAccordionOpen());
                    }}
                >
                  <span>Outline</span>
                  <div>
                    <img
                        width={20}
                        height={20}
                        alt="Accordian"
                        src={"https://static.thenounproject.com/png/551749-200.png"}
                    />
                  </div>
                </div>
                {state?.compose?.isOutlineAccordionOpen && (
                    <>
                      <div className={`editor-wrapper`}>
                        <Editor
                            htmlData={state?.compose?.topicOutline}
                            handleContentChange={handleContentChange}
                        />
                      </div>
                      <div className="mt-20 generate-btn-wrapper">
                        <button
                            onClick={handleTextGenerateFromOutline}
                            className="generate-btn"
                        >
                          Generate Text
                        </button>
                      </div>
                    </>
                )}
              </div>
          )}
          <div ref={wordProcessorRef}>
            {state?.compose?.isWordProcessorVisible && data && (
                <div style={{ marginTop: 20 }}>
                  <SyncfusionWordProcessor
                userid={userData?.ped_user_id}
                      role="Academician"
                      transactionId={generateUniqueId()}
                      // data={state?.compose?.textFromTopicOutline}
                      data={data}
                  />
                </div>
            )}
          </div>
        </div>
      </>
  );
};

export default Compose;
