import React, { useState } from "react";
import "./header.css"; // Import your CSS file for styling
import logo from "./images/full_logo.JPEG";

const Header = ({ setIsExpanded, isExpanded }) => {
  return (
    <div className="header">
      <div className="left-section">
        <img width={50} height={50} src={logo} alt="Logo" className="logo" />
      </div>
      <div className="right-section">
        {/* <div className="login-info">
          last logged in <br /> 30 Jan, 2024, 4:50 AM
        </div>
        <div className="profile-info">
          <div className="profile-icon-wrapper">
            <img src={require("./images/profileIcon.png")} alt="Avatar" />
            <span className="user_name">John Doe</span>
          </div>
          <div className="dropdown-content">
            <a href="#">Profile</a>
            <a href="#">Settings</a>
            <a href="#">Logout</a>
          </div>
        </div> */}
        <div className="cart">
          <img
            width={30}
            height={30}
            onClick={() => setIsExpanded(!isExpanded)}
            src={
              "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Hamburger_icon.svg/1200px-Hamburger_icon.svg.png"
            }
            alt="Avatar"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
