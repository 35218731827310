import HourGlass from "../../components/shared/images/hourglass.jpg";

import "./loader.css";

const Loader = ({ message }) => {
  return (
    <div className="loader">
      <div className="loader-container">
        <img src={HourGlass} className="loader-hourglass" alt="Hour Glass" />
        <div>
          <h3 className="loader-text">{message}</h3>
          <p className="loader-info">It may take around 30 seconds</p>
        </div>
      </div>
    </div>
  );
};

export default Loader;
