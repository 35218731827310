// import { createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
// import { SERVER_URL } from "../../../utils/constant";

// export const getComposeSearchData = createAsyncThunk(
//   "compose/getComposeSearchData",
//   async (record, thunkAPI) => {
//     try {
//       const url = `${SERVER_URL}/ped-getsummariesfromtopic`;
//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//           // authorization: `${localStorage.getItem("token")}`,
//         },
//       };

//       const response = await axios.post(url, record);

//       const { data } = response;

//       if (response.status === 200) {
//         console.log("success", data);
//         return data;
//       } else {
//         console.log(data);
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (e) {
//       console.log(e);
//       return thunkAPI.rejectWithValue(e);
//     }
//   }
// );

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "../../../utils/constant";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const generateUniqueId = () => `tranx${Math.floor(Math.random() * 1000000)}`;

const retryApiCall = async (
  apiFunc,
  args,
  transactionId,
  retries = 2,
  delay = 15000
) => {
  let priorTranIds = [transactionId];
  for (let i = 0; i < retries; i++) {
    try {
      const response = await apiFunc({
        ...args,
        transactionId: priorTranIds[i],
        priorTranIds: priorTranIds.slice(0, i).join(","),
      });
      if (response.status === 200) return response;
      if (response.status !== 503 && response.status !== 500)
        throw new Error(response.statusText);
    } catch (error) {
      if (i === retries - 1) throw error;
      priorTranIds.push(generateUniqueId());
      await sleep(delay);
    }
  }
};

export const getComposeSearchData = createAsyncThunk(
  "compose/getComposeSearchData",
  async (record, thunkAPI) => {
    const url = `${SERVER_URL}/ped-getsummariesfromtopic`;
    const transactionId = generateUniqueId();
    const apiFunc = async (args) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          // authorization: `${localStorage.getItem("token")}`,
        },
      };
      return axios.post(url, { ...record, ...args }, config);
    };

    try {
      const response = await retryApiCall(apiFunc, record, transactionId);
      const { data } = response;
      console.log("success", data);
      return data;
    } catch (e) {
      console.log(e);
      return thunkAPI.rejectWithValue(e.response?.data || e.message);
    }
  }
);
