import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { CircleX } from "lucide-react";
import React, { useRef, useState } from "react";
import styles from "./index.module.css";

const SendEmail = ({ link, setShow }) => {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm("service_50yqh2y", "template_2a16frb", form.current, {
        publicKey: "mKtH9kmUwbMCMVlVa",
      })
      .then(
        (result) => {
          console.log("Result", result);
          console.log("SUCCESS!");
          toast.success("Email sent successfully");
          setLoading(false);
          setShow(false);
        },
        (error) => {
          setLoading(false);
          console.log("FAILED...", error.text);
          toast.error("Unable to send the email");
        }
      );
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.inner_container}>
        <div className={styles.closeBtnContainer}>
          <CircleX className={styles.closeBtn} onClick={() => setShow(false)} />
        </div>
        <form className={styles.forms} ref={form} onSubmit={sendEmail}>
          <label className={styles.label}>Name</label>
          <input className={styles.input} type="text" name="to_name" />
          <label className={styles.label}>Email</label>
          <input className={styles.input} type="email" name="reply_to" />
          <input type="hidden" name="from_name"  value="Deb Halder" />
          <input type="hidden" name="message" value={link} />
          <input type="hidden" className={styles.input}  name="from_email" value="jijo.yopi@oodles.io" />
          <input className={styles.btn} type="submit" value={loading ? "Sending" : "Send"} disabled={loading} />
        </form>
      </div>
    </div>
  );
};

export default SendEmail;