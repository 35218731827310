import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

import { UserAuthenticationStatus } from "../redux/slices/auth/authSlice";

const PrivateRoutes = () => {
  const is_authenticated = useSelector(UserAuthenticationStatus);
  // console.log("is_authenticated", is_authenticated);
  return is_authenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
