import { Helmet } from "react-helmet";

import styles from "./index.module.css";

const Workspace = () => {
  return (
    <>
      <Helmet>
        <title>Pioneer Ed Tech | Workspace</title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Workspace</h1>
          <p className={styles.description}>
            This screen is under active development. Please check again later.
          </p>
        </div>
      </div>
    </>
  );
};

export default Workspace;
