import {Helmet} from "react-helmet";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useRef, useState, useEffect} from "react";

import Loader from "../../components/shared/loader";

import {convertFileToSFDT} from "../../redux/actions/general/convertFileToSFDT";
import {useGenerateQuickTextMutation} from "../../redux/slices/quickText/quickTextApiSlice";
import SyncfusionWordProcessor from "../../components/shared/SyncFusionWordProcessor/SyncfusionWordProcessor";

import {
  documentIsNotSaved,
  DocumentSaved,
  openWarningMessage,
  // TextGenrated,
  textIsGenerated,
  textIsNotGenerated,
} from "../../redux/slices/quickText/quickTextSlice";
import {updateCleanData} from "../../redux/slices/compose";
// import { selectCurrentUser } from "../../redux/slices/auth/authSlice";
import {generateUniqueId, getCurrentDateTimeUTC} from "../../utils/constant";

import "./index.css";
import { selectCurrentUser } from "../../redux/slices/auth/authSlice";
export default function Component() {
  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);

  const [data, setData] = useState(null);
  const wordProcessorRef = useRef(null);
  const [lastSearchedTopic, setLastSearchedTopic] = useState("");
  const [lastEssayLength, setLastEssayLength] = useState("");
  const state = useSelector((state) => state);
  const isDocumentSaved = useSelector(DocumentSaved);
  // const textGenerated = useSelector(TextGenrated);
  // const userData = useSelector(selectCurrentUser);
  const [isActive, setIsActive] = useState(null);
  const [retryCountdown, setRetryCountdown] = useState(null);
  const [loading, setLoading] = useState(false); // New loading state

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      topic: "",
      essayLength: "",
    },
  });
  useEffect(() => {
    // Parse the URL query parameters
    const queryParams = new URLSearchParams(window.location.search);
    // Get the value of the 'query' parameter
    const topicValue = queryParams.get('query');
    // Set the default value for 'topic' using setValue
    if (topicValue) {
      setValue('topic', topicValue);
    }
  }, [setValue]);

  const [generateQuickText, {isLoading}] = useGenerateQuickTextMutation();

  const topicWatch = watch("topic");
  const essayLengthWatch = watch("essayLength");

  // console.log("compose", state.compose.cleanData);
  // console.log("isDocumentSaved", isDocumentSaved, textGenerated);

  // async function retryApiCall(
  //   apiFunc,
  //   originalArgs,
  //   retries = 3,
  //   delay = 10000
  // ) {
  //   let args = { ...originalArgs };
  //   let priorTranIds = [];
  //   for (let i = 0; i < retries; i++) {
  //     if (i > 0) {
  //       args.priorTranIds = priorTranIds.join(", ");
  //       args.transactionId = generateUniqueId();
  //     }
  //     try {
  //       if (i > 0) {
  //         setRetryCountdown(delay / 1000);
  //         setIsActive(true);
  //         await new Promise((resolve) => setTimeout(resolve, delay));
  //         setRetryCountdown(null);
  //         setIsActive(false);
  //       }
  //       const result = await apiFunc(args).unwrap();
  //       return result;
  //     } catch (err) {
  //       priorTranIds.push(args.transactionId);
  //       console.log(`Attempt ${i + 1}: Retrying after error: ${err.message}`);
  //     }
  //   }
  //   throw new Error("Failed after multiple retries.");
  // }
  async function retryApiCall(
      apiFunc,
      originalArgs,
      retries = 3,
      delay = 10000,
      setLoadingCallback // Callback to set loading state
  ) {
    setLoadingCallback(true); // Set loading state before retrying
    let args = {...originalArgs};
    let priorTranIds = [];
    for (let i = 0; i < retries; i++) {
      if (i > 0) {
        args.priorTranIds = priorTranIds.join(", ");
        args.transactionId = generateUniqueId();
      }
      try {
        if (i > 0) {
          setRetryCountdown(delay / 1000);
          setIsActive(true);
          await new Promise((resolve) => setTimeout(resolve, delay));
          setRetryCountdown(null);
          setIsActive(false);
        }
        const result = await apiFunc(args).unwrap();
        setLoadingCallback(false); // Reset loading state after successful call
        return result;
      } catch (err) {
        priorTranIds.push(args.transactionId);
        console.log(`Attempt ${i + 1}: Retrying after error: ${err.message}`);
      }
    }
    setLoadingCallback(false); // Ensure loading state is reset even on failure
    throw new Error("Failed after multiple retries.");
  }

  const onSubmit = async (formData) => {
    if (data && !isDocumentSaved) {
      dispatch(openWarningMessage());
      return;
    }

    if (!formData.essayLength) {
      toast.error("Please select an essay size.");
      return;
    }

    const initialTransactionId = generateUniqueId();
    const obj = {
      transactionId: initialTransactionId,
      userid: userData?.ped_user_id,
      // userId: userData.ped_user_id,
      role: "Academician",
      topic: formData.topic,
      essaySize: formData.essayLength,
      requesttimeinUTC: getCurrentDateTimeUTC(),
    };

    try {
      dispatch(documentIsNotSaved());
      dispatch(updateCleanData(false));
      // const response = await retryApiCall(generateQuickText, obj);
      const response = await retryApiCall(
          generateQuickText,
          obj,
          3,
          10000,
          setLoading
      ); // Pass setLoading as a callback
      // console.log("response_response_line138_quickText", response?.data);
      console.log("response_response_line138_quickText", response.Response);
      // if (response?.data && response?.data?.Response) {
      if (response?.Response) {
        // Assuming convertFileToSFDT is an async action creator that returns a promise
        const responseData = await dispatch(
            // convertFileToSFDT(response?.data?.Response)
            convertFileToSFDT(response?.Response)
        );
        setLastSearchedTopic(formData.topic.trim());
        setLastEssayLength(formData.essayLength);
        // Assuming responseData.payload is a JSON string
        setData(JSON.parse(responseData.payload));
        dispatch(textIsGenerated());
      } else {
        toast.error("No response URL received, cannot convert file.");
      }
    } catch (error) {
      setLastEssayLength("");
      setLastSearchedTopic("");
      dispatch(textIsNotGenerated());
      console.error("Error generating text:", error);
      toast.error("Failed to generate text after retries.");
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      wordProcessorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [data]);

  useEffect(() => {
    if (isDocumentSaved) {
      setLastEssayLength("");
      setLastSearchedTopic("");
    }
  }, [isDocumentSaved]);

  const resetFields = () => {
    setData(null);
    setLastEssayLength("");
    setLastSearchedTopic("");
    // setValue("topic", "");
    // setValue("essayLength", "");
  };

  useEffect(() => {
    if (state.compose.cleanData) {
      resetFields();
    }
  }, [state]);

  // useEffect(() => {
  //   let interval = null;
  //   if (isActive && retryCountdown !== null && retryCountdown > 0) {
  //     interval = setInterval(() => {
  //       setRetryCountdown((retryCountdown) => retryCountdown - 1);
  //     }, 1000);
  //   } else if (!isActive && retryCountdown !== null && retryCountdown === 0) {
  //     setIsActive(false);
  //   }
  //   return () => clearInterval(interval);
  // }, [isActive, retryCountdown]);

  return (
      <>
        <Helmet>
          <title>Pioneer Ed Tech | Compose | Quick Text</title>
        </Helmet>
        <div key="1" className="">
          {(loading || isLoading || state?.general?.searchLoading) && (
              <Loader message="Generating Text"/>
          )}
          <h1 className="title">Create your essay in a snap! ✨</h1>
          <div className="essay-info">
            <p className="section-description">
              <span className="section-title">Short</span> (Under 600 words):
              Quick insights in less than a minute.
            </p>
            <p className="section-description">
              <span className="section-title">Medium</span> (Around 1200 words):
              More depth, ready in 1 to 1.5 minutes.
            </p>
            <p className="section-description">
              <span className="section-title">Large</span> (2000-3000 words):
              Comprehensive analysis, takes 2 to 3 minutes.
            </p>
            <p className="section-description">
              <span className="section-title">Edit, and Download</span> your
              masterpiece once generated.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-section">
              <p className="instruction">
                Get Started: Pick a topic, select essay length, and let's go!
              </p>
              <div className="mt-20  input-container ">
                <input
                    type="text"
                    className="search-input"
                    placeholder="Please enter a topic"
                    {...register("topic", {
                      required: "Topic is required",
                      validate: (value) =>
                          value?.trim().length >= 4 ||
                          "Topic must be at least 4 characters",
                    })}
                />
              </div>
              {errors.topic && (
                  <p className="error-message">{errors.topic.message}</p>
              )}
              <div className="options">
                {["s", "m", "l"].map((size, index) => (
                    <div className="option" key={size}>
                      <input
                          className="radio"
                          id={size}
                          type="radio"
                          value={size}
                          {...register("essayLength", {
                            required: "Please select an essay size",
                          })}
                      />
                      <label className="label radio-text" htmlFor={size}>
                        {["Short essay", "Medium essay", "Large essay"][index]}
                      </label>
                    </div>
                ))}
                <button
                    type="submit"
                    className="quick-text-generate-btn"
                    style={{
                      cursor:
                          topicWatch?.trim() === lastSearchedTopic &&
                          essayLengthWatch === lastEssayLength &&
                          "not-allowed",
                    }}
                    disabled={
                        isLoading ||
                        (topicWatch.trim() === lastSearchedTopic &&
                            essayLengthWatch === lastEssayLength)
                    }
                >
                  Generate Text
                </button>
              </div>
              <div className="options">
                {errors.essayLength && (
                    <p className="error-message">{errors.essayLength.message}</p>
                )}
              </div>
            </div>
          </form>
          <div ref={wordProcessorRef}>
            {data && (
                <div className="text-area-section">
                  <div style={{marginTop: 20}}>
                    <SyncfusionWordProcessor
                        text=""
                        data={data}
                  userid={userData?.ped_user_id}
                        role="Academician"
                        transactionId={generateUniqueId()}
                    />
                  </div>
                </div>
            ) }
          </div>
        </div>
      </>
  );
}
