// import axios from "axios";
// import { toast } from "react-toastify";
// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { CONVERT_FILE_URL, SERVER_URL } from "../../../utils/constant";

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
// const generateUniqueId = () => `tranx${Math.floor(Math.random() * 1000000)}`;
// // const getCurrentDateTimeUTC = () => new Date().toISOString();

// async function retryApiCall(
//   apiFunc,
//   args,
//   transactionId,
//   retries = 3,
//   delay = 10000
// ) {
//   let priorTranIds = [transactionId];
//   for (let i = 0; i < retries; i++) {
//     try {
//       const response = await apiFunc({
//         ...args,
//         transactionId: priorTranIds[i],
//         priorTranIds: priorTranIds.slice(0, i).join(","),
//       });
//       if (response.status === 200) return response;
//       if (response.status !== 503 && response.status !== 500)
//         throw new Error(response.statusText);
//     } catch (error) {
//       if (i === retries - 1) throw error;
//       priorTranIds.push(generateUniqueId());
//       await sleep(delay);
//     }
//   }
// }

// // const retryApiCall = async (
// //   apiFunc,
// //   originalArgs,
// //   retries = 3,
// //   delay = 10000
// // ) => {
// //   let args = { ...originalArgs };
// //   let priorTranIds = [];
// //   for (let i = 0; i < retries; i++) {
// //     if (i > 0) {
// //       args.priorTranIds = priorTranIds.join(", ");
// //       args.transactionId = generateUniqueId();
// //     }
// //     try {
// //       if (i > 0) {
// //         setRetryCountdown(delay / 1000);
// //         setIsActive(true);
// //         await new Promise((resolve) => setTimeout(resolve, delay));
// //         setRetryCountdown(null);
// //         setIsActive(false);
// //       }
// //       const result = await apiFunc(args).unwrap();
// //       return result;
// //     } catch (err) {
// //       priorTranIds.push(args.transactionId);
// //       console.log(`Attempt ${i + 1}: Retrying after error: ${err.message}`);
// //     }
// //   }
// //   throw new Error("Failed after multiple retries.");
// // };

// async function axiosPost({ url, data, transactionId, priorTranIds }) {
//   return axios.post(url, { ...data, transactionId, priorTranIds });
// }

// export const getTextFromTopicOutline = createAsyncThunk(
//   "compose/getTextFromTopicOutline",
//   async (record, thunkAPI) => {
//     const url = `${SERVER_URL}/ped-getdocfromtopicoutline`;
//     try {
//       const response = await retryApiCall(
//         axiosPost,
//         { url: url, data: record },
//         record.transactionId
//       );
//       console.log("outline from topic response", response.data);
//       if (response.data?.Response) {
//         const conversionResult = await convertFile(response.data.Response);
//         console.log("conversionResult", conversionResult);
//         return conversionResult;
//       } else {
//         toast.error("No valid response for conversion found.");
//         return thunkAPI.rejectWithValue(
//           "No valid response for conversion found."
//         );
//       }
//     } catch (e) {
//       console.error("Error in getTextFromTopicOutline:", e);
//       toast.error("Failed to process the request");
//       return thunkAPI.rejectWithValue(e.message);
//     }
//   }
// );

// const convertFile = async (fileUrl) => {
//   try {
//     const response = await retryApiCall(
//       fetchCall,
//       {
//         url: `${CONVERT_FILE_URL}/doc2sfdt`,
//         options: {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify(fileUrl),
//         },
//       },
//       generateUniqueId()
//     );
//     if (response.ok) {
//       toast.success("Text Generated Successfully");
//       console.log(response);
//       //   return await response.Response.json();
//       return response.url;
//     } else {
//       throw new Error("Failed to convert file");
//     }
//   } catch (error) {
//     console.error("Error converting file:", error);
//     toast.error("Conversion failed after retries");
//     throw error;
//   }
// };

// async function fetchCall({ url, options }) {
//   return fetch(url, options);
// }
import axios from "axios";
import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONVERT_FILE_URL, SERVER_URL } from "../../../utils/constant";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const generateUniqueId = () => `tranx${Math.floor(Math.random() * 1000000)}`;
// const getCurrentDateTimeUTC = () => new Date().toISOString();

async function retryApiCall(
  apiFunc,
  args,
  transactionId,
  retries = 7,
  delay = 15000
) {
  let priorTranIds = [transactionId];
  for (let i = 0; i < retries; i++) {
    try {
      const response = await apiFunc({
        ...args,
        transactionId: priorTranIds[i],
        priorTranIds: priorTranIds.slice(0, i).join(","),
      });
      if (response.status === 200) return response;
      if (response.status !== 503 && response.status !== 500)
        throw new Error(response.statusText);
    } catch (error) {
      if (i === retries - 1) throw error;
      priorTranIds.push(generateUniqueId());
      await sleep(delay);
    }
  }
}

async function axiosPost({ url, data, transactionId, priorTranIds }) {
  return axios.post(url, { ...data, transactionId, priorTranIds });
}

export const getTextFromTopicOutline = createAsyncThunk(
  "compose/getTextFromTopicOutline",
  async (record, thunkAPI) => {
    const url = `${SERVER_URL}/ped-getdocfromtopicoutline`;
    try {
      const response = await retryApiCall(
        axiosPost,
        { url: url, data: record },
        record.transactionId
      );
      console.log("outline from topic response", response.data);
      if (response.data?.Response) {
        const conversionResult = await convertFile(response.data.Response);
        console.log("conversionResult", conversionResult);
        return conversionResult;
      } else {
        toast.error("No valid response for conversion found.");
        return thunkAPI.rejectWithValue(
          "No valid response for conversion found."
        );
      }
    } catch (e) {
      console.error("Error in getTextFromTopicOutline:", e);
      toast.error("Failed to process the request");
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

const convertFile = async (fileUrl) => {
  try {
    const response = await retryApiCall(
      fetchCall,
      {
        url: `${CONVERT_FILE_URL}/doc2sfdt`,
        options: {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(fileUrl),
        },
      },
      generateUniqueId()
    );
    if (response.ok) {
      toast.success("Text Generated Successfully");
      return await response.json();
    } else {
      throw new Error("Failed to convert file");
    }
  } catch (error) {
    console.error("Error converting file:", error);
    toast.error("Conversion failed after retries");
    throw error;
  }
};

async function fetchCall({ url, options }) {
  return fetch(url, options);
}
