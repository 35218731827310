import { X } from "lucide-react";

import styles from "./index.module.css";

const TranformSuccessBanner = ({ 
  link,
  title,
  pageNo,
  setText,
  setPage,
  setIsError,
  setIsSuccess,
  setOpenSuccessLink,
}) => {

  const download = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = link;
    downloadLink.setAttribute("text", true);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className={styles.success_banner}>
      <div className={styles.success_banner_container}>
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <CheckCircleIcon className={styles.icon} />
            <div>
              {title} has been generated successfully. Please click on this
              <span
                onClick={download}
                style={{
                  margin: "0 5px 0 5px",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                link
              </span>
              to download it.
            </div>
          </div>
          <button
            className={`${styles.ghostButton} ${styles.textWhite}`}
            onClick={() => {
              setPage(pageNo);
              setText("");
              setIsError(false);
              setIsSuccess(false);
              setOpenSuccessLink(false);
            }}
          >
            <X className={styles.buttonIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TranformSuccessBanner;

function CheckCircleIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
      <polyline points="22 4 12 14.01 9 11.01" />
    </svg>
  );
}
