import {Loader} from "lucide-react";
import {Helmet} from "react-helmet";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from 'react-router-dom';
import {useLoginMutation} from "../../redux/slices/auth/authApiSlice";
import {generateUniqueId, getCurrentDateTimeUTC} from "../../utils/constant";
import {
  setCredentials,
  UserAuthenticationStatus,
} from "../../redux/slices/auth/authSlice";

import logo from "../../components/shared/images/full_logo.JPEG";
import LoginWithKey from "../../components/authentication/login-with-access-keys/LoginWithKey";

import styles from "./index.module.css";
import { Eye, EyeOff } from "lucide-react";
import ReactGA from "react-analytics-ga4";

export default function Login() {
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [login, {isLoading}] = useLoginMutation();
  const is_authenticated = useSelector(UserAuthenticationStatus);
  const [isPassword, setIsPassword] = useState(true);
  const history = useNavigate();

  const handleLoginButton = () => {
    ReactGA.event({
      category: 'Login Button',
      action: 'click',
      label: 'Login button clicked!',
      value: 1,
    });
  };
  useEffect(() => {
    function getExpectedDNS() {
      return process.env.REACT_APP_BASE_URL;
    }

    if (is_authenticated) {
      const expectedDNS = getExpectedDNS();
      const currentDNS = window.location.hostname;
      if (currentDNS === expectedDNS) {
        history(-1);
      }
      navigate("/");
    }
  }, [navigate, is_authenticated]);

  useEffect(() => {
    setPreviousPath(location.pathname);
  }, [location.pathname]);

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      pwdEn: "",
    },
  });

  const onSubmit = async (data) => {
    // amonkS@gmail.com
    // HIUG&^%*&^
    const obj = {
      ...data,
      transactionId: generateUniqueId(),
      requesttimeinUTC: getCurrentDateTimeUTC(),
    };

    // console.log("Submit", obj);
    try {
      const response = await login(obj).unwrap();
      if (response) {
        navigate("/");
        dispatch(setCredentials(response));
        toast.success("User successfully logged in");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.error);
    }
  };

  return (
      <>
        <Helmet>
          <title>Pioneer Ed Tech | Login</title>
        </Helmet>
        {!show ? (
            <div className={styles.screen_container}>
              <div className={styles.form_container}>
                <div className={styles.logo_container}>
                  <img alt="Logo" src={logo} className={styles.signup_logo}/>
                  {/* <p className={styles.help} onClick={() => setShow(true)}>Login with access key</p> */}
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                  <input
                      className={`${styles.input}`}
                      placeholder="Email"
                      {...register("email", {
                        required: "Email is required",
                      })}
                  />
                  {errors.email && (
                      <p className={styles.error_message}>{errors.email.message}</p>
                  )}
              <div className={`${styles.input}`}>
                  <input
                  className={styles.passwordInput}
                      placeholder="Password"
                  type={isPassword ? "password" : "text"}
                      {...register("pwdEn", {
                        required: "Password is required",
                      })}
                  />
                {isPassword ? (
                  <Eye size={22} onClick={() => setIsPassword(!isPassword)} />
                ) : (
                  <EyeOff
                    size={22}
                    onClick={() => setIsPassword(!isPassword)}
                  />
                )}
              </div>
                  {errors.pwdEn && (
                      <p className={styles.error_message}>{errors.pwdEn.message}</p>
                  )}
                  <div className={`${styles.button_group}`}>
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`${styles.button} ${styles.outline}`}
                    >
                      {isLoading ? (
                          <Loader
                              className={`${styles.loader_button} ${styles.loader_spin}`}
                          />
                      ) : (
                          "Login"
                      )}
                    </button>
                    <p onClick={() => navigate("/signup")} className={styles.help}>
                      New User?
                      <span className={styles.help_underline}> Signup</span>
                    </p>
                  </div>
                </form>
              </div>
            </div>
        ) : (
            <LoginWithKey setShow={setShow}/>
        )}
      </>
  );
}
