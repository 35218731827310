import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { routes } from "../../utils/constant";
import { logOut, selectCurrentUser } from "../../redux/slices/auth/authSlice";
import {
  DocumentSaved,
  openWarningMessage,
  ShowWarningModal,
  TextGenrated,
} from "../../redux/slices/quickText/quickTextSlice";

import SidebarProgress from "./progress-message/sidebar-progress";

import logo from "./images/full_logo.JPEG";
import { CircleX } from "lucide-react";

import "./sidebar.css";

const Sidebar = ({ isExpanded, setIsExpanded }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeRoute, setActiveRoute] = useState(location.pathname);

  const isDocumentSaved = useSelector(DocumentSaved);
  const textGenerated = useSelector(TextGenrated);
  const warningMessage = useSelector(ShowWarningModal);
  const userData = useSelector(selectCurrentUser);

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);

  useEffect(() => {
    // Function to find the parent route's href for the current URL path
    const findActiveDropdown = () => {
      for (const route of routes) {
        if (route.dropdown) {
          const dropdownItem = route.dropdown.find(
            (item) => location.pathname === item.href
          );
          if (dropdownItem) {
            // If a dropdown item matches the current URL path, return the parent route's href
            return route.href;
          }
        }
      }
      // If no match is found, return null
      return null;
    };

    // Set the activeDropdown state based on the current URL path
    const activeDropdownHref = findActiveDropdown();
    setActiveDropdown(activeDropdownHref);
  }, [location, routes]);

  const isActiveRoute = (route) => {
    // if(route === activeRoute) console.log("actibve routeeee", route)
    return (
      (route.dropdown &&
        route.dropdown.some((item) => activeRoute === item.href)) ||
      activeRoute === route
    );
  };

  const handleClick = (route) => {
    if (route.dropdown && route.dropdown.length > 0) {
      if (activeDropdown !== route.href) {
        if (textGenerated && !isDocumentSaved) {
          dispatch(openWarningMessage());
          return;
        }
        navigate(route.dropdown[0].href);
        setActiveDropdown(route.href); // This line ensures the dropdown is opened
      } else {
        if (textGenerated && !isDocumentSaved) {
          dispatch(openWarningMessage());
          return;
        }
        setActiveDropdown(activeDropdown === route.href ? null : route.href);
      }
    } else {
      if (textGenerated && !isDocumentSaved) {
        dispatch(openWarningMessage());
        return;
      }
      navigate(route.href);
      setActiveDropdown(null); // This line ensures no dropdown is opened when navigating to a non-dropdown route
    }
  };

  const handleLogout = () => {
    dispatch(logOut());
    toast.success("User logged out");
  };
  // console.log("isExpanded??", isExpanded);

  return (
    <>
      {warningMessage && <SidebarProgress />}
      <div className="sidebar">
        <div className="logo">
          <img src={logo} alt="Logo" />
          {isExpanded && (
            <button
              className="close_sidebar_btn"
              onClick={() => setIsExpanded(false)}
            >
              <CircleX color="red" />
            </button>
          )}
        </div>
        <div className="buttons">
          {routes.map((route) => (
            <React.Fragment key={route.id}>
              <button
                onClick={() => {
                  handleClick(route);
                  setIsExpanded(!isExpanded);
                }}
                className={`sidebar-btn-style ${
                  isActiveRoute(route.href) ||
                  (route.dropdown &&
                    route.dropdown.some((item) => isActiveRoute(item.href)))
                    ? "active-button"
                    : ""
                }`}
              >
                <img
                  src={
                    isActiveRoute(route.href) ||
                    (route.dropdown &&
                      route.dropdown.some((item) => isActiveRoute(item.href)))
                      ? route.activeImage
                      : require(`${route.image}`)
                  }
                  alt="Logo"
                />
                <div className="btn-title-wrapper">
                  {route.heading}
                  <br />
                  {route.subHeading && (
                    <span
                      className={`btn-sub-heading ${
                        isActiveRoute(route.href) ? "active-button" : ""
                      }`}
                    >
                      {route.subHeading}
                    </span>
                  )}
                </div>
              </button>

              {route.dropdown && activeDropdown === route.href && (
                <>
                  {console.log("actualdropDown?", activeDropdown)}
                  <div className="dropdown-buttons-container">
                    {route.dropdown.map((dropdownItem) => (
                      <button
                        key={dropdownItem.id}
                        className="dropdown-buttons"
                        style={{
                          backgroundColor: isActiveRoute(dropdownItem.href)
                            ? "#2A5F81"
                            : "",
                          color: isActiveRoute(dropdownItem.href)
                            ? "white"
                            : "",
                        }}
                        onClick={() => {
                          if (textGenerated && !isDocumentSaved) {
                            dispatch(openWarningMessage());
                            return;
                          }
                          navigate(dropdownItem.href);
                          setIsExpanded(!isExpanded);
                        }}
                      >
                        {dropdownItem.heading}
                      </button>
                    ))}
                  </div>
                </>
              )}
            </React.Fragment>
          ))}

          <div className="bottom_container">
            <div className="profile-info">
              <div className="profile-icon-wrapper">
                <div className="avatar">
                  <span className="user_name">
                    {userData?.firstName?.slice(0, 1)}{" "}
                    {userData?.lastName?.slice(0, 1)}
                  </span>
                </div>
              </div>
              {/* {userData.email === undefined && <h1>UD</h1>} */}
              <div className="dropdown-content">
                <p>Profile</p>
                <p>Settings</p>
                <p onClick={handleLogout}>Logout</p>
              </div>
            </div>
            <div className="logout-btn" onClick={handleLogout}>
              <img src={require("./images/dark/logout.png")} alt="Logo" />
              <span className="logout-btn-text">Logout</span>
            </div>
          </div>

          <div className="login-info">
            <p>Last logged in</p>
            <p>30 March, 2024, 5:50 PM</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
