import {Loader} from "lucide-react";
import {Helmet} from "react-helmet";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router";
import {useState, useEffect} from "react";
import logo from "../../components/shared/images/full_logo.JPEG";
import {setCredentials, UserAuthenticationStatus} from "../../redux/slices/auth/authSlice";
import {generateUniqueId, getCurrentDateTimeUTC} from "../../utils/constant";
import {
    useGetEmailVerificationMutation,
    useLoginMutation,
    useSignUpMutation,
    useVerifyEmailMutation,
} from "../../redux/slices/auth/authApiSlice";
import termsAndConditionsFile
    from "../../components/TermsAndConditions_PrivacyPolicy/PioneerEdTech-WebsiteTermsNConditions.pdf"
import privacyPolicyFile from "../../components/TermsAndConditions_PrivacyPolicy/PioneerEDTech-Privacy Policy.pdf"
import GenerateToken from "../../components/authentication/generate-access-token/GenerateToken";

import styles from "./index.module.css";
import {Eye, EyeOff} from "lucide-react";

export default function Signup() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const is_authenticated = useSelector(UserAuthenticationStatus);
    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm();

    const [otpSent, setOtpSent] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [emailValid, setEmailValid] = useState(true);
    const [show, setShow] = useState(false);
    const [data, setData] = useState({});
    const [emailTouched, setEmailTouched] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // Add loading state
    const [isPassword, setIsPassword] = useState(true);
    const [signUp, {isLoading}] = useSignUpMutation();
    const [getEmailVerification, {isLoading: generatingCode}] =
        useGetEmailVerificationMutation();
    const [verifyEmail, {isLoading: verifyingEmail}] = useVerifyEmailMutation();
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    //delete line below later
    const [login, {isLoadingg}] = useLoginMutation();

    const email = watch("email");

    function getExpectedDNS() {
        return process.env.REACT_APP_BASE_URL;
    }

    useEffect(() => {
        if (is_authenticated) {
            const expectedDNS = getExpectedDNS();
            const currentDNS = window.location.hostname;
            if (currentDNS === expectedDNS) {
                navigate(-2);
            }
            navigate("/");
        }
    }, [navigate, is_authenticated]);

    useEffect(() => {
        setEmailValid(validateEmail(email));
    }, [email]);

    const validateEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailRegex.test(email);
    };

    const onSubmit = async (data) => {
        setIsSubmitting(true); // Set loading state
        const obj = {
            ...data,
            transactionId: generateUniqueId(),
            requesttimeinUTC: getCurrentDateTimeUTC(),
        };

        try {
            const response = await signUp(obj).unwrap();
            if (response) {
                const res = await login(obj).unwrap(); // delete this line later
                navigate("/");
                dispatch(setCredentials(res)); // res should be response
                // setShow(true);
                // setData(response);
                toast.success("User successfully signed up");
            }
        } catch (error) {
            console.log(error);
            toast.error(error?.data?.error);
        } finally {
            setIsSubmitting(false); // Reset loading state after navigation
        }
    };

    const generateCode = async (event) => {
        event.preventDefault();
        if (email) {
            const obj = {
                email,
                transactionId: generateUniqueId(),
                requesttimeinUTC: getCurrentDateTimeUTC(),
            };
            try {
                const response = await getEmailVerification(obj).unwrap();
                if (response.Response === "success") {
                    setOtpSent(true);
                    toast.success("An OTP has been sent to your email address");
                }
            } catch (error) {
                toast.error(error?.data?.error);
            }
        }
    };

    const handleVerifyEmail = async (event) => {
        event.preventDefault();
        const emailCode = watch("emailCode");
        if (email && emailCode) {
            const obj = {
                email,
                emailCode,
                transactionId: generateUniqueId(),
                requesttimeinUTC: getCurrentDateTimeUTC(),
            };
            try {
                const response = await verifyEmail(obj).unwrap();
                if (response.Response === "success") {
                    setEmailVerified(true);
                    setIsOtpVerified(true);
                    toast.success("Your email address has been verified");
                }
            } catch (error) {
                toast.error(error?.data?.error);
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>Pioneer Ed Tech | Signup</title>
            </Helmet>
            {!show ? (
                <div className={styles.screen_container}>
                    <div className={styles.form_container}>
                        <div className={styles.logo_container}>
                            <img alt="Logo" src={logo} className={styles.signup_logo}/>
                            <p className={styles.help} style={{display: "none"}}>
                                Signup with access key
                            </p>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                            <div className={styles.form_group}>
                                <div>
                                    <input
                                        className={`${styles.input} ${styles.full_width}`}
                                        placeholder="First Name"
                                        {...register("firstName", {
                                            required: "First Name is required",
                                        })}
                                    />
                                    {errors.firstName && (
                                        <p className={styles.error_message}>
                                            {errors.firstName.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <input
                                        className={`${styles.input} ${styles.full_width}`}
                                        placeholder="Last Name"
                                        {...register("lastName", {
                                            required: "Last Name is required",
                                            onChange: () => {
                                                const lastName = watch("lastName");
                                                if (lastName) {
                                                    // Logic to enable email field if last name is not empty
                                                }
                                            },
                                        })}
                                    />
                                    {errors.lastName && (
                                        <p className={styles.error_message}>
                                            {errors.lastName.message}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div className={styles.form_group_secondary}>
                                <div className={`${styles.input_secondary_container}`}>
                                    <input
                                        className={`${styles.input_secondary}`}
                                        placeholder="Email"
                                        type="email"
                                        {...register("email", {
                                            required: "Email is required",
                                            // validate: validateEmail,
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Invalid email address",
                                            },
                                            onBlur: () => setEmailTouched(true),
                                        })}
                                        disabled={!watch("firstName") || !watch("lastName")}
                                    />

                                    {!emailValid && emailTouched && (
                                        <p className={styles.error_message}>
                                            Invalid email address
                                        </p>
                                    )}
                                </div>
                                {<button
                                    className={styles.generateBtn}
                                    onClick={generateCode}
                                    disabled={!email || generatingCode || !emailValid}
                                >
                                    {generatingCode ? (
                                        <Loader
                                            className={`${styles.loader_button} ${styles.loader_spin}`}
                                        />
                                    ) : (
                                        "Generate OTP to verify"
                                    )}
                                </button>}
                            </div>

                            {<div
                                className={styles.form_group_secondary}
                                style={{marginBottom: ".75rem"}}
                            >
                                <div className={`${styles.input_secondary_container}`}>
                                    <input
                                        className={`${styles.input_secondary}`}
                                        placeholder="OTP (6 digit)"
                                        type="password"
                                        {...register("emailCode", {
                                            required: "OTP is required",
                                        })}
                                        style={{display: otpSent ? "block" : "none"}}
                                    />
                                    {errors.emailCode && (
                                        <p className={styles.error_message}>
                                            {errors.emailCode.message}
                                        </p>
                                    )}
                                </div>
                                <button
                                    className={styles.generateBtn}
                                    onClick={handleVerifyEmail}
                                    disabled={!watch("emailCode") || !otpSent || verifyingEmail}
                                    style={{display: otpSent ? "block" : "none"}}
                                >
                                    {verifyingEmail ? (
                                        <Loader
                                            className={`${styles.loader_button} ${styles.loader_spin}`}
                                        />
                                    ) : (
                                        "Verify"
                                    )}
                                </button>
                            </div>}

                            <div className={styles.form_group_bottom}>
                                {isOtpVerified && (
                                    <div className={styles.input}>
                                        <input
                                            className={`${styles.passwordInput}`}
                                            placeholder="Password"
                                            type={isPassword ? "password" : "text"}
                                            {...register("pwdEn", {
                                                required: "Password is required",
                                            })}
                                            disabled={!emailVerified}
                                        />
                                        {isPassword ? (
                                            <Eye size={22} onClick={() => setIsPassword(!isPassword)}/>
                                        ) : (
                                            <EyeOff
                                                size={22}
                                                onClick={() => setIsPassword(!isPassword)}
                                            />
                                        )}
                                    </div>
                                )}
                                {errors.pwdEn && (
                                    <p className={styles.error_message}>{errors.pwdEn.message}</p>
                                )}

                                <div className={`${styles.check_box_div}`}>
                                    <div className={`${styles.check_box}`}>
                                        <input id="terms" className={`${styles.check_box}`}
                                               type="checkbox"

                                               {...register("checkbox", {
                                                   required: "Please accept the Terms and Conditions",
                                               })}

                                        />
                                    </div>
                                    <div className={`${styles.check_box_lable}`}>
                                        <label htmlFor="terms">
                                            <small>
                                                I agree to the <a href={termsAndConditionsFile} download>Terms and
                                                Conditions</a> and <a href={privacyPolicyFile} download>Privacy
                                                Policies</a> of PioneerEducationTech Private Limited
                                            </small>
                                        </label>
                                    </div>

                                </div>
                                {errors.checkbox && (
                                    <p className={styles.error_message}>{errors.checkbox.message}</p>
                                )}
                                <div className={`${styles.button_group}`}>
                                    <button
                                        type="submit"
                                        disabled={isLoading || isSubmitting} // Disable button while loading
                                        disabled={isLoading || !emailVerified}
                                        className={`${styles.button} ${styles.outline}`}
                                    >
                                        {isLoading || isSubmitting ? ( // Show loader while loading
                                            <Loader
                                                className={`${styles.loader_button} ${styles.loader_spin}`}
                                            />
                                        ) : (
                                            "Signup"
                                        )}
                                    </button>
                                    <p onClick={() => navigate("/login")} className={styles.help}>
                                        Already have an account?
                                        <span className={styles.help_underline}> Log in</span>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            ) : (
                <GenerateToken userData={data}/>
            )}
        </>
    );
}
