import axios from "axios";
import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONVERT_FILE_URL } from "../../../utils/constant";

export const convertFileToSFDT = createAsyncThunk(
  "general/convertFileToSFDT",
  async (record, thunkAPI) => {
    try {
      const url = `${CONVERT_FILE_URL}/doc2sfdt`;

      const config = {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
        // Credential: "same-origin"
      };

      const response = await axios.post(url, JSON.stringify(record), config);

      const { data } = response;

      if (response.status === 200) {
        toast.success("Text Generated Successfully");
        return data;
      } else {
        toast.error("Something went wrong");
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      toast.error("Error: " + e.message);
      return thunkAPI.rejectWithValue(e);
    }
  }
);
