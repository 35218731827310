import React, { useState } from "react";
import "./quizQr.css";
import { CircleX } from "lucide-react";

const QuizQrModal = ({ isOpen, onClose, ...props }) => {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          className="qr-code"
          viewBox="0 0 1200 1200"
          onClick={onClose}
          {...props}
        >
          <path d="M0 0v545.312h545.312V0H0zm654.688 0v545.312H1200V0H654.688zM108.594 108.594h328.125v328.125H108.594V108.594zm654.687 0h328.125v328.125H763.281V108.594zM217.969 219.531v108.594h110.156V219.531H217.969zm653.906 0v108.594h108.594V219.531H871.875zM0 654.688V1200h545.312V654.688H0zm654.688 0V1200h108.595V873.438h108.594v108.595H1200V654.688h-108.594v108.595H980.469V654.688H654.688zM108.594 763.281h328.125v328.125H108.594V763.281zm109.375 108.594v110.156h110.156V871.875H217.969zm653.906 219.531V1200h108.594v-108.594H871.875zm219.531 0V1200H1200v-108.594h-108.594z" />
        </svg>
      </div>
      <CircleX className="X-btn" onClick={onClose} />
    </div>
  );
};

const QuizQr = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={400}
        height={400}
        viewBox="0 0 1200 1200"
        onClick={handleClick}
        {...props}
      >
        <path d="M0 0v545.312h545.312V0H0zm654.688 0v545.312H1200V0H654.688zM108.594 108.594h328.125v328.125H108.594V108.594zm654.687 0h328.125v328.125H763.281V108.594zM217.969 219.531v108.594h110.156V219.531H217.969zm653.906 0v108.594h108.594V219.531H871.875zM0 654.688V1200h545.312V654.688H0zm654.688 0V1200h108.595V873.438h108.594v108.595H1200V654.688h-108.594v108.595H980.469V654.688H654.688zM108.594 763.281h328.125v328.125H108.594V763.281zm109.375 108.594v110.156h110.156V871.875H217.969zm653.906 219.531V1200h108.594v-108.594H871.875zm219.531 0V1200H1200v-108.594h-108.594z" />
      </svg>
      <QuizQrModal isOpen={isModalOpen} onClose={handleClick} />
    </>
  );
};

export default QuizQr;
