import { useState } from "react";
import { Upload } from "lucide-react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Loader from "../../../loader";
import GenerateDocument from "../GenerateDocument";
import {
  generateUniqueId,
  getCurrentDateTimeUTC,
} from "../../../../../utils/constant";
import { sendTransformData } from "../../../../../redux/slices/transform/transformSlice";
import {
  useVerifyDocumentMutation,
  useVerifyPDFMutation,
  useVerifyPPTMutation,
} from "../../../../../redux/slices/transform/transformApiSlice";

import styles from "./index.module.css";
import Notifications from "../../../../../pages/Notifications";
import DownloaderComponent from "../../DownloaderComponent/DownloaderComponent";
import { selectCurrentUser } from "../../../../../redux/slices/auth/authSlice";

const FromFiles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [fileName, setFileName] = useState("");
  const [data, setData] = useState({});
  const [type, setType] = useState("");
  const [instructions, setInstructions] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [base64, setBase64] = useState("");
  const [fileExtension, setFileExtension] = useState("");

  const userData = useSelector(selectCurrentUser);

  const [fileError, setFileError] = useState(false);

  const [verifyDocument, { isLoading: verifying }] =
    useVerifyDocumentMutation();
  const [verifyPDF, { isLoading: verifyingPDF }] = useVerifyPDFMutation();
  const [verifyPPT, { isLoading: verifyingPPT }] = useVerifyPPTMutation();

  const onClickNext = async () => {
    if (isSuccess) {
      setPage((prev) => prev + 1);
      return;
    }

    if (base64 === "") {
      toast.error("Please upload a document");
      return;
    }

    const initialTransactionId = generateUniqueId();
    const obj = {
      userid: userData?.ped_user_id,
      transactionId: initialTransactionId,
      fileName,
      fileContentBase64: base64,
      requesttimeinUTC: getCurrentDateTimeUTC(),
    };

    try {
      let response;
      if (fileExtension === "docx") {
        response = await verifyDocument(obj).unwrap();
      } else if (fileExtension === "pdf") {
        response = await verifyPDF(obj).unwrap();
      } else if (fileExtension === "pptx") {
        response = await verifyPPT(obj).unwrap();
      }

      if (response) {
        setData(response);
        setIsError(false);
        setIsSuccess(true);
      } else {
        setIsError(true);
        setIsSuccess(false);
        toast.error("Please try again later");
      }
    } catch (error) {
      setIsError(true);
      setIsSuccess(false);
      console.error("Error", error);
      toast.error("Please try again later");
    }
  };

  const generateDocument = async () => {
    if (type === "") {
      toast.error("Please select a rendering type");
      return;
    }

    const obj = {
      userid: userData?.ped_user_id,
      transactionId: data.transactionId,
      fileName,
      renderingType: type,
      instruction: instructions,
      fileContentBase64: base64,
      requesttimeinUTC: getCurrentDateTimeUTC(),
    };
    setPage((page) => page + 1);

    if (fileExtension === "docx") {
      // do not navigate
      // navigate("/notifications");
      dispatch(sendTransformData({ type: "doc2Doc", obj }));
    } else if (fileExtension === "pdf") {
      // navigate("/notifications");
      dispatch(sendTransformData({ type: "pdf2Doc", obj }));
    } else if (fileExtension === "pptx") {
      // navigate("/notifications");
      dispatch(sendTransformData({ type: "ppt2Doc", obj }));
    }
  };

  const handleUpload = async (acceptedFiles) => {
    setIsSuccess(false);
    const filteredFiles = acceptedFiles.filter((file) => {
      const isDocument =
        (file.type === "application/pdf" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
          file.type === "application/qz") &&
        file.size <= 1 * 1024 * 1024;

      return isDocument;
    });

    if (filteredFiles.length > 0) {
      const file = filteredFiles[0];
      setFileName(file.name);
      const extension = file.name.split(".").pop();
      setFileExtension(extension);
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        setBase64(base64String);
      };
      reader.readAsDataURL(file);
      setFileError(false);
    } else {
      setFileName("");
      setFileExtension("");
      setFileError(true);
    }
  };

  const DisplayPage = (pageNo) => {
    switch (pageNo) {
      case 0:
        return (
          <ToDocumentInput
            handleUpload={handleUpload}
            fileName={fileName}
            isError={isError}
            isSuccess={isSuccess}
            fileError={fileError}
          />
        );
      case 1:
        return (
          <GenerateDocument
            setType={setType}
            instructions={instructions}
            setInstructions={setInstructions}
          />
        );
      case 2:
        return <DownloaderComponent />;
      default:
        return "Page Not Found";
    }
  };

  return (
    <div>
      {verifying && <Loader message="Verifying Document" />}
      {verifyingPDF && <Loader message="Verifying PDF" />}
      {verifyingPPT && <Loader message="Verifying PPT" />}

      {DisplayPage(page)}
      <div className={styles.nextButtonContainer}>
        {page === 0 && (
          <button onClick={onClickNext} className={styles.nextButton}>
            Next
          </button>
        )}
        {page === 1 && (
          <button onClick={generateDocument} className={styles.generateButton}>
            Generate Document
          </button>
        )}
      </div>
    </div>
  );
};

export default FromFiles;

const ToDocumentInput = ({
  handleUpload,
  fileName,
  isError,
  isSuccess,
  fileError,
}) => {
  const acceptFormats = {
    "application/pdf": [".pdf"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    // "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    //   [".pptx"],
    // "application/qz": [".qz"],
  };

  return (
    <div>
      <p className={styles.paragraph}>
        To ensure smooth processing, kindly note that files should not exceed 1
        MB in size. Supported file formats for upload include Word Documents
        (docx) and PDF Documents (pdf). Let's embark on this creative journey!
      </p>
      <div>
        <div>
          <Dropzone
            multiple={false}
            accept={acceptFormats}
            onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={styles.inputField}>
                <input {...getInputProps()} />
                <input
                  className={styles.input}
                  placeholder="Please upload a file"
                  type="text"
                  value={fileName}
                  disabled
                />
                <div className={styles.uploadIconContainer}>
                  <Upload className={styles.uploadIcon} />
                </div>
              </div>
            )}
          </Dropzone>
        </div>
        <div className={styles.infoContainer}>
          {fileError && (
            <div className={styles.errorText}>
              File size/type does not meet the criteria.
            </div>
          )}
          {isError && (
            <div className={styles.errorText}>
              Sorry for the inconvenience. Please try again later.
            </div>
          )}
          {isSuccess && (
            <div className={styles.successText}>
              The uploaded file matches all criteria for document tranformation.
              We shall start processing processing now. Please click on the Next
              button below to provide certain configuration details.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
