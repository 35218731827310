import { closest, enableRipple } from '@syncfusion/ej2-base';
import { MenuComponent } from '@syncfusion/ej2-react-navigations';
import * as React from 'react';
import * as ReactDom from 'react-dom';
import {Link} from "@syncfusion/ej2-react-richtexteditor";
enableRipple(true);
function DropDownButton({ link }) {
    // Menu items definition
    let menuItems = [
        {
            items: [
                { text: 'English Version',url: link, component: Link},
            ],
            text: 'Download'
        },
    ];
    function onBeforeOpen(args) {
        // Getting parent menu item element offset
        const relativeOffset = closest(args.event.target, '.e-menu-item').getBoundingClientRect();
        // Getting sub menu wrapper element using closest method
        const subMenuEle = closest(args.element, '.e-menu-wrapper');
        subMenuEle.style.display = 'block';
        args.top = (relativeOffset.top - subMenuEle.getBoundingClientRect().height);
        args.left = relativeOffset.left ;
        subMenuEle.style.display = '';
    }
    return (<MenuComponent items={menuItems} beforeOpen={onBeforeOpen}/>);
}
export default DropDownButton;