import { createSlice } from "@reduxjs/toolkit";

const quickTextSlice = createSlice({
  name: "quickText",
  initialState: {
    textGenerated: false,
    isDocumentSaved: false,
    warningMessage: false,
  },
  reducers: {
    documentIsSaved: (state) => {
      state.isDocumentSaved = true;
    },
    documentIsNotSaved: (state) => {
      state.isDocumentSaved = false;
    },
    textIsGenerated: (state) => {
      state.textGenerated = true;
    },
    textIsNotGenerated: (state) => {
      state.textGenerated = false;
    },
    openWarningMessage: (state) => {
      state.warningMessage = true;
    },
    closeWarningMessage: (state) => {
      state.warningMessage = false;
    },
  },
});

export const {
  documentIsSaved,
  documentIsNotSaved,
  textIsGenerated,
  textIsNotGenerated,
  openWarningMessage,
  closeWarningMessage
} = quickTextSlice.actions;

export default quickTextSlice.reducer;

export const DocumentSaved = (state) => state.quickText.isDocumentSaved;
export const TextGenrated = (state) => state.quickText.textGenerated;
export const ShowWarningModal = (state) => state.quickText.warningMessage;