import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    ped_user_id: "null",
    firstName: "",
    lastName: "",
    email: "",
    is_authenticated: false,
    userData: { ped_user_id: "null", firstName: "", lastName: "", email: "" },
  },
  reducers: {
    setCredentials: (state, action) => {
      const { firstName, lastName, userid, email } = action.payload;
      state.ped_user_id = userid;
      state.firstName = firstName;
      state.lastName = lastName;
      state.email = email;
      state.userData = {
        ped_user_id: userid,
        firstName: firstName,
        lastName: lastName,
        email: email,
      };
      state.is_authenticated = true;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setSignupData: (state, action) => {
      state.signUpData = action.payload;
    },
    logOut: (state, action) => {
      state.email = null;
      state.lastName = null;
      state.firstName = null;
      state.ped_user_id = null;
      state.is_authenticated = false;
      localStorage.removeItem("persist:root");
    },
  },
});

export const { setCredentials, setUserData, setSignupData, logOut } =
  authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.userData;
export const UserAuthenticationStatus = (state) => state.auth.is_authenticated;
