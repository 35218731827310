import { useEffect } from "react";
import { Loader } from "lucide-react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { useLoginByAccessKeyMutation } from "../../../redux/slices/auth/authApiSlice";
import {
  generateUniqueId,
  getCurrentDateTimeUTC,
} from "../../../utils/constant";
import {
  setCredentials,
  UserAuthenticationStatus,
} from "../../../redux/slices/auth/authSlice";

import logo from "../../../components/shared/images/full_logo.JPEG";

import styles from "./index.module.css";
import { useState } from "react";

export default function LoginWithKey({ setShow }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginByAccessKey, { isLoading }] = useLoginByAccessKeyMutation();
  const is_authenticated = useSelector(UserAuthenticationStatus);
  const [type, setType] = useState("password");

  useEffect(() => {
    if (is_authenticated) {
      navigate("/");
    }
  }, [navigate, is_authenticated]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      asseccKey: "",
    },
  });

  const accessKeyInput = watch("asseccKey");

  useEffect(() => {
    setValue("asseccKey", accessKeyInput.toUpperCase());
  }, [accessKeyInput, setValue]);

  const setPasswordType = () => {
    setType(type === "password" ? "text" : "password");
  };

  const onSubmit = async (data) => {
    // TGW9FT
    const obj = {
      asseccKey: data.asseccKey,
      transactionId: generateUniqueId(),
      requesttimeinUTC: getCurrentDateTimeUTC(),
    };

    try {
      const response = await loginByAccessKey(obj).unwrap();
      if (response) {
        navigate("/");
        dispatch(setCredentials(response));
        toast.success("User successfully logged in");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.error);
    }
  };

  return (
    <div className={styles.screen_container}>
      <div className={styles.form_container}>
        <div className={styles.logo_container}>
          <img alt="Logo" src={logo} className={styles.signup_logo} />
          <p className={styles.help} onClick={() => setShow(false)}>
            Login with email
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.inputWrapper}>
            <div className={styles.inputContainer}>
              <input
                className={`${styles.input}`}
                type={type}
                placeholder="Access Key (AGH34E)"
                maxLength={6}
                //react hook form not working for some reason
                {...register("asseccKey", {
                  required: "Access Key is required",
                  maxLength: 6,
                  minLength: 6,
                })}
              />
              <span className={styles.eyeContainer} onClick={setPasswordType}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                </svg>
              </span>
            </div>
            {errors.asseccKey && (
              <p className={styles.error_message}>{errors.asseccKey.message}</p>
            )}
          </div>

          <div className={`${styles.button_group}`}>
            <button
              type="submit"
              disabled={isLoading}
              className={`${styles.button} ${styles.outline}`}
            >
              {isLoading ? (
                <Loader
                  className={`${styles.loader_button} ${styles.loader_spin}`}
                />
              ) : (
                "Login"
              )}
            </button>
            <p onClick={() => navigate("/signup")} className={styles.help}>
              New User? Signup
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}
