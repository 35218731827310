import { useState, useRef } from "react";
import { Download, Share2 } from "lucide-react";
import Select from "react-select";

import QuizQr from "../QuizQr";
import Loader from "../../../loader";
import SendEmail from "../send-email/SendEmail";

import styles from "./index.module.css";

const QuizPage = ({ data, link, quizLoading, answerFile, questionFile }) => {
  const titleRef = useRef();
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(data?.quizName);
  const [isOpen, setIsOpen] = useState(false);
  const editTitle = () => {
    setTitle(titleRef.current.value);
  };
  const options = [
    { value: "Questions", label: "Questions" },
    { value: "Questions and Answers", label: "Questions and Answers" },
  ];

  const download = (type) => {
    const downloadLink = document.createElement("a");
    if (type === "Q") {
      downloadLink.href = questionFile;
      downloadLink.target = "_blank";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      downloadLink.href = answerFile;
      downloadLink.target = "_blank";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const handleShareClick = (title, link) => {
    if (!link) {
      console.error("No link provided for sharing");
      return;
    }
    const email = "mailto:email@email.com";
    const subject = encodeURIComponent(title);
    const encodedLink = encodeURIComponent(link);
    const body = encodeURIComponent(
      `Hello,\n\nHere is the link to the Quiz.\n\nTo download the quiz file, please use the following link:\n\n${encodedLink}\n\nBest regards,\n${email}`
    );
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Quiz Questions</h1>
      {quizLoading && <Loader message="Loading Quiz" />}
      {show && (
        <SendEmail
          link={link}
          title={"Hey! Check out this quiz."}
          setShow={setShow}
        />
      )}
      <div className={styles.infoContainer}>
        <textarea
          type="text"
          rows={2}
          ref={titleRef}
          value={title}
          onChange={editTitle}
          className={styles.title}
          disabled
        />
        <div className={styles.infoContainerRight}>
          <div className={styles.downloadLinkWrapper}>
            <div>
              <button
                className={styles.downloadBTN}
                onClick={() => setIsOpen(!isOpen)}
              >
                Download File &darr;
              </button>
            </div>
            {isOpen && (
              <ul className={styles.dropdown_menu}>
                <li
                  className={styles.dropdown_item}
                  onClick={() => download("Q")}
                >
                  With Answers <Download size={18} />
                </li>
                <li
                  className={styles.dropdown_item}
                  onClick={() => download("A")}
                >
                  Without Answers <Download size={18} />
                </li>
              </ul>
            )}
          </div>
          <Share2
            className={styles.share}
            onClick={() => handleShareClick(title, link)}
          />
          <QuizQr height="60" width="60" />
        </div>
      </div>
      <div className={styles.quizContent}>
        {data?.questions.map((item) => (
          <div
            className={styles.quizContentContainer}
            key={item?.quizContentContainer}
          >
            <div className={styles.questionContainer}>
              <span className={styles.questionNumber}>
                {item?.questionNumber}.
              </span>
              <span className={styles.questionText}>{item?.questionText}</span>
            </div>
            {item?.answerOptions?.map((el) => {
              return (
                <div key={el?.optionNumber} className={styles.answerContainer}>
                  <ul>
                    <li>{el?.optionText}</li>
                  </ul>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuizPage;
