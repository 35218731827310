import { useState } from "react";
import { Helmet } from 'react-helmet';


import DocumentSidebar from "../../components/shared/Transform/To-Documentation/DocumentSidebar";

import FromText from "../../components/shared/Transform/To-Documentation/from-text/FromText";
import FromFiles from "../../components/shared/Transform/To-Documentation/from-files/FromFiles";
import FromInternet from "../../components/shared/Transform/To-Documentation/from-internet/FromInternet";

import styles from "./index.module.css";

export default function Component() {
  const [tab, setTab] = useState(0);

  const Sections = ["From Files", "From Internet", "From Text"];

  const DisplayPage = (tab) => {
    switch (tab) {
      case 0:
        return <FromFiles />;
      case 1:
        return <FromInternet />;
      case 2:
        return <FromText />;
      default:
        return;
    }
  };

  return (
    <>
      <Helmet>
        <title>Pioneer Ed Tech | Transform | Document</title>
      </Helmet>
      <div className={styles.container}>
        <h1 className={styles.title}>
          Discover the magic of converting your content into captivating
          documents! From dynamic presentations or text and versatile internet
          content, explore the possibilities. Ready to bring your ideas to life?
          Simply upload the file you'd like to tranform into a stunning
          document.
        </h1>
        <div className={styles.tabContainer}>
          <DocumentSidebar tab={tab} setTab={setTab} Sections={Sections} />
          <div className={styles.tabContent}>{DisplayPage(tab)}</div>
        </div>
      </div>
    </>
  );
}
