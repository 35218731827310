import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { homeContent } from "../../utils/constant";

import styles from "./index.module.css";

const Dashboard = () => {
  const navigate = useNavigate();
  let curr = navigate.currPath;
  const [currPath, setCurrPath] = useState(curr);

  const handleRoute = (link, isDropdown = false) => {
    setCurrPath(link);
    navigate(link);
  };

  // useEffect(() => {
  //   console.log("currPath", navigate.pathname);
  // }, [navigate.pathname, currPath]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>Harness the Power of Generative AI!</h2>
      </div>
      <div className={styles.content_container}>
        {homeContent.map((elem) => (
          <div className={styles.content} key={elem.id}>
            <div className={styles.img_container}>
              <img src={elem.image} alt={elem.title} className={styles.img} />
            </div>
            <div className={styles.text_content}>
              <div className={styles.content_title}>{elem.title}:</div>
              <div className={styles.content_description}>
                {elem.description}
              </div>
              <span
                onClick={() => handleRoute(elem.route)}
                className={styles.content_actionTitle}
              >
                {elem.actionTitle}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
