import { useState } from "react";

import TawkToChat from "./utils/TawkToChat";

import Header from "./components/shared/header";
import Sidebar from "./components/shared/sidebar";

import "react-tooltip/dist/react-tooltip.css";
import "./App.css";

function Layout({ children }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="App">
      <div className={`sidebar-main ${isExpanded ? "viewSidebar" : ""}`}>
        <Sidebar isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      </div>
      <div className="nav-content-wrapper">
        <div className="navbar">
          <Header isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
        </div>
        <hr style={{ margin: 0 }} />
        {/* <TawkToChat /> */}
        <div className="content">{children}</div>
      </div>
    </div>
  );
}

export default Layout;
