import { useEffect } from "react";
import Select from "react-select";

import styles from "./index.module.css";

const questionOptions = [
  { value: "Default", label: "Default" },
  { value: "1-5", label: "1-5" },
  { value: "5-10", label: "5-10" },
  { value: "10-15", label: "10-15" },
];

const difficultyLevelOptions = [
  { value: "Simple", label: "Simple" },
  { value: "Medium", label: "Medium" },
  { value: "Hard", label: "Hard" },
  { value: "Very Hard", label: "Very Hard" },
];

const typesOptions = [
  { value: "Multiple Choice", label: "Multiple Choice" },
  { value: "True/False", label: "True/False" },
  { value: "Multi-select questions", label: "Multi-select questions" },
  { value: "Fill in the blanks", label: "Fill in the blanks" },
  { value: "Matching questions", label: "Matching questions" },
  { value: "Ranking/ordering", label: "Ranking/ordering" },
];

const defaultQuestionOptions = questionOptions[0];
const defaultDifficultyLevelOptions = difficultyLevelOptions[0];
// const defaultTypesOptions = typesOptions[0];

const GenerateQuiz = ({
  explanation,
  setExplanation,
  setQuestionCount,
  setDifficulty,
  setQuestionType,
}) => {
  const toggle = () => setExplanation((o) => !o);

  useEffect(() => {
    setQuestionCount(questionOptions[0].value);
    setDifficulty(difficultyLevelOptions[0].value);
    // setQuestionType(typesOptions[0].value);
  }, [setQuestionCount, setDifficulty, setQuestionType]);

  return (
    <div className={styles.documentGeneration}>
      <p className={styles.paragraph}>
        Kindly share the configuration details needed to generate the quiz. We
        will use the provided inputs to create the most optimal quiz.
      </p>
      <div className={styles.documentTypeContainer}>
        <div className={styles.selectDocumentType}>
          <div className={styles.selectDocumentGroup}>
            <label className={styles.selectDocumentLabel}>
              No. of questions
            </label>
            <Select
              options={questionOptions}
              defaultValue={defaultQuestionOptions}
              onChange={(e) => setQuestionCount(e.value)}
            />
          </div>
          <div className={styles.selectDocumentGroup}>
            <label className={styles.selectDocumentLabel}>Question types</label>
            <Select
              isMulti
              options={typesOptions}
              // defaultValue={defaultTypesOptions}
              onChange={(e) => {
                setQuestionType(e);
              }}
              closeMenuOnSelect={false}
            />
          </div>
        </div>
        <div className={styles.selectDocumentType}>
          <div className={styles.selectDocumentGroup}>
            <label className={styles.selectDocumentLabel}>
              Difficuly level
            </label>
            <Select
              options={difficultyLevelOptions}
              defaultValue={defaultDifficultyLevelOptions}
              onChange={(e) => setDifficulty(e.value)}
            />
          </div>
          <div className={styles.checkboxAnimate}>
            <label style={{ textAlign: "center" }}>
              <input
                type="checkbox"
                name="check"
                checked={explanation}
                onChange={toggle}
                className={styles.input}
              />
              <span className={styles.inputCheck}></span>I like to generate
              answers for each question
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateQuiz;
