import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Layout from "./Layout";
import Login from "./pages/Login";
import Signup from "./pages/Signup";

import Quiz from "./pages/Quiz";
import Video from "./pages/Video";
import Compose from "./pages/Compose";
import Billing from "./pages/Billing";
import Document from "./pages/Document";
import Settings from "./pages/Settings";
import QuickText from "./pages/QuickText";
import Workspace from "./pages/Workspace";
import Dashboard from "./pages/dashboard";
import Translate from "./pages/Translate";
import Transform from "./pages/Transform";
import Presentation from "./pages/Presentation";
import Notifications from "./pages/Notifications";

// import PublicRoutes from "./utils/PublicRoutes";
import PrivateRoutes from "./utils/PrivateRoutes";

import "react-tooltip/dist/react-tooltip.css";

import "./App.css";
import TawkToChat from "./utils/TawkToChat";
import ReactGA from "react-analytics-ga4";
ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
function App() {
  return (
      <Router>
        <TawkToChat />
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Layout children={<Dashboard />} />} />
            <Route
                path="/quick-text"
                element={<Layout children={<QuickText />} />}
            />
            <Route
                path="/guided-text"
                element={<Layout children={<Compose />} />}
            />
            <Route
                path="/workspace"
                element={<Layout children={<Workspace />} />}
            />
            <Route path="/billing" element={<Layout children={<Billing />} />} />
            <Route
                path="/notifications"
                element={<Layout children={<Notifications />} />}
            />
            <Route
                path="/translate"
                element={<Layout children={<Translate />} />}
            />
            <Route
                path="/document"
                element={<Layout children={<Document />} />}
            />
            <Route
                path="/presentation"
                element={<Layout children={<Presentation />} />}
            />
            <Route path="/quiz" element={<Layout children={<Quiz />} />} />
            <Route path="/video" element={<Layout children={<Video />} />} />
            <Route
                path="/transform"
                element={<Layout children={<Transform />} />}
            />
            <Route
                path="/settings"
                element={<Layout children={<Settings />} />}
            />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </Router>
  );
}

export default App;
