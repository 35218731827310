import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";

import Loader from "../../../loader";
import GenerateDocument from "../GenerateDocument";

import { sendTransformData } from "../../../../../redux/slices/transform/transformSlice";
import { useVerifyTextMutation } from "../../../../../redux/slices/transform/transformApiSlice";
import {
  generateUniqueId,
  getCurrentDateTimeUTC,
  quickToolbarSettings,
  toolbarSettings,
} from "../../../../../utils/constant";

import DownloaderComponent from "../../DownloaderComponent/DownloaderComponent";

import styles from "./index.module.css";
import { selectCurrentUser } from "../../../../../redux/slices/auth/authSlice";

const FromText = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(selectCurrentUser)

  const [page, setPage] = useState(0);
  const [text, setText] = useState("");
  const [data, setData] = useState({});
  const [type, setType] = useState("");
  const [instructions, setInstructions] = useState("");

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [verifyText, { isLoading: verifying }] = useVerifyTextMutation();

  const onClickNext = async () => {
    if (isSuccess) {
      setPage((prev) => prev + 1);
      return;
    }

    if (text.length <= 5) {
      return;
    }

    const initialTransactionId = generateUniqueId();
    const obj = {
      transactionId: initialTransactionId,
      userid: userData?.ped_user_id,
      text,
      requesttimeinUTC: getCurrentDateTimeUTC(),
    };

    try {
      const response = await verifyText(obj).unwrap();
      if (response) {
        setData(response);
        setIsError(false);
        setIsSuccess(true);
      } else {
        setIsError(true);
        setIsSuccess(false);
        toast.error("Please try again later");
      }
    } catch (error) {
      setIsError(true);
      setIsSuccess(false);
      console.error("Error", error);
      toast.error("Please try again later");
    }
  };

  const generateDocument = async () => {
    if (type.length <= 0) {
      toast.error("Please select a rendering type");
      return;
    }

    const obj = {
      userid: userData?.ped_user_id,
      transactionId: data.transactionId,
      renderingType: type,
      instruction: instructions,
      text,
      requesttimeinUTC: getCurrentDateTimeUTC(),
    };

    setPage((page) => page + 1);
    // navigate("/notifications");
    dispatch(sendTransformData({ type: "text2Doc", obj }));
  };

  const DisplayPage = (pageNo) => {
    switch (pageNo) {
      case 0:
        return (
          <ToDocumentInput
            text={text}
            setText={setText}
            isError={isError}
            isSuccess={isSuccess}
          />
        );
      case 1:
        return (
          <GenerateDocument
            setType={setType}
            instructions={instructions}
            setInstructions={setInstructions}
          />
        );
      case 2:
        return <DownloaderComponent />;
      default:
        return "Page Not Found";
    }
  };

  return (
    <div>
      {verifying && <Loader message="Verifying the text" />}
      {DisplayPage(page)}
      <div className={styles.nextButtonContainer}>
        {page === 0 && (
          <button onClick={onClickNext} className={styles.nextButton}>
            Next
          </button>
        )}
        {page === 1 && (
          <button
            onClick={generateDocument}
            className={styles.generateButton}
            disabled={verifying}
          >
            Generate Document
          </button>
        )}
      </div>
    </div>
  );
};

export default FromText;

const ToDocumentInput = ({ text, setText, isError, isSuccess }) => {
  const handleChange = (e) => {
    const plainText = e.value.replace(/<[^>]*>/g, "");
    setText(plainText);
  };

  return (
    <div>
      <p className={styles.paragraph}>
        Please paste your text from any sources for document transformation
        purposes. Maximum allowed words are 10,000.
      </p>
      <div>
        <RichTextEditorComponent
          height={450}
          toolbarSettings={toolbarSettings}
          quickToolbarSettings={quickToolbarSettings}
          value={text}
          change={(e) => {
            handleChange(e);
          }}
        >
          <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
        </RichTextEditorComponent>
      </div>
      <div className={styles.infoContainer}>
        {isError && (
          <div className={styles.errorText}>
            Provided text may contain inappropriate content. It can not be used
            for tranformation purposes. Please recheck the text.
          </div>
        )}
        {isSuccess && (
          <div className={styles.successText}>
            The uploaded file matches all criteria for document transformation.
            We shall start processing now. Please click on the Next button below
            to provide certain configuration details.
          </div>
        )}
      </div>
    </div>
  );
};
