import { apiSlice } from "../../api/apiSlice";

export const transformApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    verifyDocument: builder.mutation({
      query: (data) => ({
        url: "/ped-verifydocument",
        method: "POST",
        body: { ...data },
      }),
    }),
    verifyPDF: builder.mutation({
      query: (data) => ({
        url: "/ped-verifypdf",
        method: "POST",
        body: { ...data },
      }),
    }),
    verifyPPT: builder.mutation({
      query: (data) => ({
        url: "/ped-verifyppt",
        method: "POST",
        body: { ...data },
      }),
    }),
    verifyWeb: builder.mutation({
      query: (data) => ({
        url: "/ped-verifyweb",
        method: "POST",
        body: { ...data },
      }),
    }),
    verifyText: builder.mutation({
      query: (data) => ({
        url: "/ped-verifytext",
        method: "POST",
        body: { ...data },
      }),
    }),
    doc2Doc: builder.mutation({
      query: (data) => ({
        url: "/ped-doc2doc",
        method: "POST",
        body: { ...data },
      }),
    }),
    pdf2Doc: builder.mutation({
      query: (data) => ({
        url: "/ped-pdf2doc",
        method: "POST",
        body: { ...data },
      }),
    }),
    ppt2Doc: builder.mutation({
      query: (data) => ({
        url: "/ped-ppt2doc",
        method: "POST",
        body: { ...data },
      }),
    }),
    doc2PPT: builder.mutation({
      query: (data) => ({
        url: "/ped-doc2ppt",
        method: "POST",
        body: { ...data },
      }),
    }),
    web2Doc: builder.mutation({
      query: (data) => ({
        url: "/ped-web2doc",
        method: "POST",
        body: { ...data },
      }),
    }),
    text2Doc: builder.mutation({
      query: (data) => ({
        url: "/ped-text2doc",
        method: "POST",
        body: { ...data },
      }),
    }),

    ppt2Video: builder.mutation({
      query: (data) => ({
        url: "/ped-ppt2video",
        method: "POST",
        body: { ...data },
      }),
    }),
    doc2Video: builder.mutation({
      query: (data) => ({
        url: "/ped-doc2video",
        method: "POST",
        body: { ...data },
      }),
    }),
    doc2Quiz: builder.mutation({
      query: (data) => ({
        url: "/ped-doc2quiz",
        method: "POST",
        body: { ...data },
      }),
    }),
    getQuizInDoc: builder.mutation({
      query: (data) => ({
        url: "/ped-getQuizInDoc",
        // url: "/ped-doc2quiz",
        method: "POST",
        body: { ...data },
      }),
    }),
  }),
});

export const {
  useVerifyDocumentMutation,
  useVerifyPDFMutation,
  useVerifyPPTMutation,
  useVerifyWebMutation,
  useVerifyTextMutation,
  useDoc2DocMutation,
  usePdf2DocMutation,
  usePpt2DocMutation,
  useDoc2PPTMutation,
  useWeb2DocMutation,
  useText2DocMutation,
  usePpt2VideoMutation,
  useDoc2VideoMutation,
  useDoc2QuizMutation,
  useGetQuizInDocMutation,
} = transformApiSlice;
