import { X } from "lucide-react";

import styles from "./index.module.css";

const TransformWarnBanner = ({
  title,
  setPage,
  setIsError,
  setIsSuccess,
  setOpenErrorLink,
}) => {
  return (
    <div className={styles.success_banner}>
      <div className={styles.success_banner_container}>
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <X className={styles.icon} />
            <div>
              Ohh! Some problems occured while generating the {title}. Please
              try again after some time.
            </div>
          </div>
          <button
            className={`${styles.ghostButton} ${styles.textWhite}`}
            onClick={() => {
              // setPage(0)
              setIsError(false);
              setIsSuccess(false);
              setOpenErrorLink(false);
            }}
          >
            <X className={styles.buttonIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransformWarnBanner;
