import { SERVER_URL, CONVERT_FILE_URL  } from "../../utils/constant";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: SERVER_URL,
  // credentials: "include",
  credentials: "same-origin", 
  // prepareHeaders: (headers) => {
  //   headers.set('Content-Type', 'application/json');
  //   return headers;
  // },
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery,
  endpoints: (builder) => ({}),
});
