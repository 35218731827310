import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "../../../utils/constant";

export const savedocumentfile = createAsyncThunk(
  "compose/savedocumentfile",
  async (record, thunkAPI) => {
    try {
      const url = `${SERVER_URL}/ped-savedocumentfile`;
      const config = {
        headers: {
          "Content-Type": "application/json",
          // authorization: `${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.post(url, record);

      const { data } = response;

      if (response.status === 200) {
        console.log("outline from topic response", data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
