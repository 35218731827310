import HourGlass from "../../../shared/images/hourglass.jpg";

import styles from "./index.module.css";

const RetryLoader = ({ message }) => {
  return (
    <div className={styles.loader}>
      <div className={styles.loader_container}>
        <img src={HourGlass} className="loader-hourglass" alt="Hour Glass" />
        <div>
          <h3 className="loader-text">{message}</h3>
        </div>
      </div>
    </div>
  );
};

export default RetryLoader;
