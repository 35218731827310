import { apiSlice } from "../../api/apiSlice";

export const quickTextApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generateQuickText: builder.mutation({
      query: (data) => ({
        url: "/ped-getquicktext",
        method: "POST",
        body: { ...data },
      }),
    }),
    saveDocuments: builder.mutation({
      query: (data) => ({
        url: "/ped-savedocumentfile",
        method: "POST",
        body: { ...data },
      }),
    }),
    convertFileToSF: builder.mutation({
      query: (fileUrl) => ({
        url: '/doc2sfdt', 
        method: 'POST',
        body: JSON.stringify(fileUrl)
      }),
    }),
  }),
});

export const {
  useGenerateQuickTextMutation,
  useSaveDocumentsMutation,
  useConvertFileToSFMutation,
} = quickTextApiSlice;
