export const material_type = [
  {
    type: "doc2PPT",
    name: "PPT",
  },
  {
    type: "doc2Quiz",
    name: "Quiz",
  },
  {
    type: "doc2Video",
    name: "Video",
  },
  {
    type: "ppt2Video",
    name: "Video",
  },
  {
    type: "doc2Doc",
    name: "Files",
  },
  {
    type: "pdf2Doc",
    name: "Files",
  },
  {
    type: "ppt2Doc",
    name: "Files",
  },
  {
    type: "web2Doc",
    name: "Web",
  }, 
  {
    type: "text2Doc",
    name: "Text",
  },
];
