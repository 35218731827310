import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import QuizPage from '../To-Quiz/quiz-page/QuizPage';
import { material_type } from '../../../../pages/Notifications/types';
import { selectCurrentUser } from '../../../../redux/slices/auth/authSlice';
import { useApiCall, ApiStatus } from '../../../../pages/Notifications/useApiCall';
import { generateUniqueId, getCurrentDateTimeUTC } from '../../../../utils/constant';
import { TransformData, clearTransformData } from '../../../../redux/slices/transform/transformSlice';
import { useDoc2DocMutation, useDoc2PPTMutation, useDoc2QuizMutation, useDoc2VideoMutation, usePdf2DocMutation, usePpt2DocMutation, usePpt2VideoMutation, useText2DocMutation, useWeb2DocMutation, useGetQuizInDocMutation } from '../../../../redux/slices/transform/transformApiSlice';

import styles from './index.module.css';

const DownloaderComponent = () => {
  let quizData;
  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);

  const [types, setTypes] = useState({});
  const [showQuiz, setShowQuiz] = useState(false);
  const [questionFile, setQuestionFile] = useState(null);
  const [answerFile, setAnswerFile] = useState(null);
  const [quizLink, setQuizLink] = useState(false);
  const transformData = useSelector(TransformData);

  const [doc2PPT] = useDoc2PPTMutation();
  const [doc2Quiz] = useDoc2QuizMutation();
  const [doc2Video] = useDoc2VideoMutation();
  const [ppt2Video] = usePpt2VideoMutation();
  const [doc2Doc] = useDoc2DocMutation();
  const [pdf2Doc] = usePdf2DocMutation();
  const [ppt2Doc] = usePpt2DocMutation();
  const [web2Doc] = useWeb2DocMutation();
  const [text2Doc] = useText2DocMutation();
  const [getQuizInDoc, { isLoading: quizLoading }] = useGetQuizInDocMutation();

  const apiFunctions = { doc2Quiz, doc2PPT, doc2Video, ppt2Video, doc2Doc, pdf2Doc, ppt2Doc, web2Doc, text2Doc };

  const [callApi, status, responseLink, quizID] = useApiCall(
    apiFunctions,
    dispatch,
    clearTransformData
  );

  useEffect(() => {
    const temp_types = material_type?.filter(
      (el) => el?.type === transformData?.type
    );
    setTypes(temp_types?.[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (transformData && transformData.obj && transformData.type) {
      const { type, obj } = transformData;
      const transactionId = generateUniqueId();
      const args = {
        ...obj,
        transactionId,
        priorTranIds: [],
      };

      callApi(type, args);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformData]);

  // console.log('types', types, status, responseLink);

  const handleDownload = () => {
    if (types?.name === 'Quiz') {
      setShowQuiz(true);
      return;
    }
    const downloadLink = document.createElement('a');
    downloadLink.href = responseLink;
    downloadLink.setAttribute('text', true);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const statusClass = classNames({
    [styles.progress]:
      ApiStatus.IN_PROGRESS || ApiStatus.COMPLETED || ApiStatus.NOT_STARTED,
    [styles.failed]: ApiStatus.FAILED,
  });

  if (types?.name === 'Quiz') {
    quizData = JSON.parse(responseLink);
  }

  const handleQuiz = async () => {
    setShowQuiz(true);
    const obj = {
      quizFileId: quizID,
      userid: userData?.ped_user_id,
      transactionId: generateUniqueId(),
      requesttimeinUTC: getCurrentDateTimeUTC(),
    };

    try {
      const response = await getQuizInDoc(obj).unwrap();
      if (response) {
        setQuizLink(response?.QnAQuizFile);
        setQuestionFile(response?.QuestionQuizFile);
        setAnswerFile(response?.QnAQuizFile)
      } else {
        toast.error('Please try again later');
      }
    } catch (error) {
      console.error('Error', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Pioneer Ed Tech | Notifications</title>
      </Helmet>
      {!showQuiz ? (
        <div className={styles.container}>
          <p className={styles.info_text}>
            Your request for generating the particular material is currenly
            being processes. Please check the following section. Once the
            requested material generation is complete, a link will be shown to
            download the material.
          </p>

          <div className={styles.table}>
            <div className={styles.column_container}>
              <span className={styles.column}>Material Type</span>
              <span className={styles.column}>Status</span>
              <span className={styles.column}>
                <span className={styles.downlink_link}>Download Link</span>
              </span>
            </div>

            <div className={styles.row_container}>
              <span className={styles.row}>
                {types ? types?.name : 'Not Available'}
              </span>
              <span className={`${styles.row} ${statusClass}`}>
                {status === ApiStatus.NOT_STARTED && ' - '}
                {status === ApiStatus.IN_PROGRESS && 'In Progress'}
                {status === ApiStatus.COMPLETED && 'Completed'}
                {status === ApiStatus.FAILED && 'Failed'}
              </span>
              {responseLink ? (
                <span
                  className={`${styles.row} ${styles.link}`}
                  onClick={types?.name === 'Quiz' ? handleQuiz : handleDownload}
                >
                  {types?.name === 'Quiz' ? 'Quiz' : 'Link'}
                </span>
              ) : (
                <span className={`${styles.row} `}>-</span>
              )}
            </div>
          </div>
          <p className={styles.info_text}>
            In case you lose the browser or lose the link to the generated
            material, we shall send you the material link in an email to your
            email address: <span className={styles.mail}>email@mail.com{userData.email}</span>{' '}
            once the material generation is complete.
          </p>
        </div>
      ) : (
        <QuizPage
          link={quizLink}
          data={quizData}
          questionFile={questionFile}
          answerFile={answerFile}
          quizLoading={quizLoading}
        />
      )}
    </>
  );
};

export default DownloaderComponent;
