import homeLight from "../components/shared/images/light/home.svg";
import postAdd from "../components/shared/images/light/post_add.svg";
import menuLight from "../components/shared/images/light/menu_book.svg";
import settingLight from "../components/shared/images/light/settings.svg";
import transformLight from "../components/shared/images/light/transform.svg";
import translateLight from "../components/shared/images/light/translate.svg";
import libraryLight from "../components/shared/images/light/library_books.svg";
import attachMoneyLight from "../components/shared/images/light/attach_money.svg";

import Quick_Text_Composition from "../components/shared/images/Quick_Text_Composition.png";
import Guided_Composition from "../components/shared/images/Guided_Composition.png";
import Transform_Your_Content from "../components/shared/images/Transform_Your_Content.png";
import Translate_Your_Content from "../components/shared/images/Translate_Your_Content.png";
import Avatar from "../components/shared/images/Avatar.png";

export const SERVER_URL =
  "https://ky45lpx7ng.execute-api.us-east-2.amazonaws.com/default";
export const CONVERT_FILE_URL =
  "https://kwixdv9ivg.execute-api.us-east-2.amazonaws.com/Prod/api";

export const routes = [
  {
    id: 1,
    href: "/",
    heading: "Home",
    activeImage: homeLight,
    image: "./images/dark/home.png",
  },
  {
    id: 2,
    href: "/workspace",
    heading: "Workspace",
    subHeading: "View your materials",
    activeImage: menuLight,
    image: "./images/dark/workspace.png",
  },
  {
    id: 3,
    heading: "Compose",
    subHeading: "Create new materials",
    href: "/compose",
    activeImage: postAdd,
    image: "./images/dark/compose.png",
    dropdown: [
      {
        id: 3.1,
        href: "/quick-text",
        heading: "Quick Text",
      },
      {
        id: 3.2,
        href: "/guided-text",
        heading: "Guided Text",
      },
    ],
  },
  {
    id: 4,
    heading: "Translate",
    subHeading: "Language support",
    href: "/translate",
    activeImage: translateLight,
    image: "./images/dark/translate.png",
  },
  {
    id: 5,
    heading: "Transform",
    subHeading: "Material formats",
    href: "/transform",
    activeImage: transformLight,
    image: "./images/dark/transform.png",
    dropdown: [
      {
        id: 5.1,
        href: "/document",
        heading: "Create Documents",
      },
      {
        id: 5.2,
        href: "/presentation",
        heading: "Create Presentations",
      },
      {
        id: 5.3,
        href: "/quiz",
        heading: "Create Quizzes",
      },
      {
        id: 5.4,
        href: "/video",
        heading: "Create Videos",
      },
    ],
  },
  {
    id: 6,
    heading: "Notifications",
    href: "/notifications",
    activeImage: libraryLight,
    image: "./images/dark/notification.png",
  },
  {
    id: 7,
    heading: "Billing",
    subHeading: "Money matters",
    href: "/billing",
    activeImage: attachMoneyLight,
    image: "./images/dark/attach_money.png",
  },
  {
    id: 8,
    heading: "Settings",
    href: "/settings",
    activeImage: settingLight,
    image: "./images/dark/settings.png",
  },
];

export const generateUniqueId = () => {
  const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";

  let uniqueId = "";
  for (let i = 0; i < 9; i++) {
    const randomCharIndex = Math.floor(Math.random() * characters.length);
    const randomNumIndex = Math.floor(Math.random() * numbers.length);
    uniqueId += characters[randomCharIndex];
    uniqueId += numbers[randomNumIndex];
  }

  uniqueId = uniqueId
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  return uniqueId;
};

export const toolbarSettings = {
  items: [
    "Bold",
    "Italic",
    "Underline",
    "StrikeThrough",
    "FontName",
    "FontSize",
    "FontColor",
    "BackgroundColor",
    "LowerCase",
    "UpperCase",
    "|",
    "Formats",
    "Alignments",
    "OrderedList",
    "UnorderedList",
    "Outdent",
    "Indent",
    "|",
    "CreateLink",
    "Image",
    "|",
    "ClearFormat",
    "Print",
    "SourceCode",
    "FullScreen",
    "|",
    "Undo",
    "Redo",
  ],
};

export const quickToolbarSettings = {
  image: [
    "Replace",
    "Align",
    "Caption",
    "Remove",
    "InsertLink",
    "OpenImageLink",
    "-",
    "EditImageLink",
    "RemoveImageLink",
    "Display",
    "AltText",
    "Dimension",
  ],
  link: ["Open", "Edit", "UnLink"],
};

export const getCurrentDateTimeUTC = () => {
  const currentDate = new Date();
  const utcDate = new Date(
    currentDate.getTime() + currentDate.getTimezoneOffset() * 60000
  );
  const formattedDate = `${
    utcDate.getUTCMonth() + 1
  }/${utcDate.getUTCDate()}/${utcDate.getUTCFullYear()} ${utcDate.getUTCHours()}:${
    utcDate.getUTCMinutes() < 10 ? "0" : ""
  }${utcDate.getUTCMinutes()}`;
  return formattedDate;
};

export const homeContent = [
  {
    id: 1,
    image: Quick_Text_Composition,
    title: "Quick Text Composition",
    description:
      "Effortlessly create essays with our Quick Text feature. Simply enter your topic and select the desired essay length to instantly generate a comprehensive essay tailored to your needs. Perfect when you need results fast.",
    actionTitle: "Explore Quick Text",
    route: "/quick-text",
  },
  {
    id: 2,
    image: Guided_Composition,
    title: "Guided Composition",
    description:
      "Craft essays with depth and precision through our Guided Composition tool. Start by exploring different perspectives on your topic, construct a detailed outline, and finally generate a polished essay. Ideal for achieving a thorough and nuanced exploration.",
    actionTitle: "Begin Guided Composition",
    route: "/guided-text",
  },
  {
    id: 3,
    image: Transform_Your_Content,
    title: "Transform Your Content",
    description:
      "Maximize the potential of your existing materials with our Transform feature. Generate documents, presentations, quizzes, and learning videos to enhance understanding and engagement. Ideal for repurposing and enriching resources.",
    actionTitle: "Transform Contents",
    route: "/document",
  },
  {
    id: 4,
    image: Translate_Your_Content,
    title: "Translate Your Content",
    description:
      "Break down language barriers with our Translate feature. Effortlessly convert your content from one language to another, enhancing accessibility and reaching a global audience.",
    actionTitle: "Explore Translation Options",
    route: "/translate",
  },
  {
    id: 5,
    image: Avatar,
    title: "Create and Publish Your Avatar",
    description:
      "Empower your teaching with our Create Your Avatar feature. Now you can build and publish digital avatars endowed with your own expertise, making them available to answer questions and assist students anytime, anywhere. Ideal for enhancing interactive learning and providing constant educational support.",
    actionTitle: "Create Your Avatar",
    route: "/",
  },
];
