import { createSlice } from "@reduxjs/toolkit";
import { getComposeSearchData } from "../actions/compose/getComposeSearchData";
import { getOutilneFromTopic } from "../actions/compose/getOutilneFromTopic";
import { getTextFromTopicOutline } from "../actions/compose/getTextFromTopicOutline";
import { getComposeFormattedData } from "../../utils/helper";
import { savedocumentfile } from "../actions/compose/savedocumentfile";

const initialState = {
  searchList: [],
  topicOutline: "",
  textFromTopicOutline: "",
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  searchLoading: false,
  selectedData: {},
  searchOriginalData: "",
  isListAccordionVisible: false,
  isListAccordionOpen: false,
  isOutlineAccordionVisible: false,
  isOutlineAccordionOpen: false,
  isWordProcessorVisible: false,
  documentUrl: "",

  outlineLoading: false,
  textLoading: false,
  savingDocument: false,
  screenDetails: {},
  cleanData: false,
};

const ComposeSlice = createSlice({
  name: "compose",
  initialState,
  reducers: {
    updateSelectedData(state, action) {
      state.selectedData = action.payload; // Update the variable in the state
    },
    updateTopicOutlineData(state, action) {
      state.topicOutline = action.payload; // Update the variable in the state
    },
    updateListAccordionOpen(state) {
      state.isListAccordionOpen = !state.isListAccordionOpen; // Update the variable in the state
    },
    updateOutlineAccordionOpen(state) {
      state.isOutlineAccordionOpen = !state.isOutlineAccordionOpen; // Update the variable in the state
    },
    updateWordProcessorVisible(state) {
      state.isWordProcessorVisible = !state.isWordProcessorVisible; // Update the variable in the state
    },
    closeListAccordion(state) {
      state.isListAccordionOpen = false;
      state.isListAccordionVisible = false;
    },
    closeOutlineAccordion(state) {
      state.isOutlineAccordionOpen = false;
      state.isOutlineAccordionVisible = false;
    },
    closeWordProcessor(state) {
      state.isWordProcessorVisible = false;
    },
    updateScreenDetails(state, action) {
      state.screenDetails = action.payload;
    },
    updateCleanData(state, action) {
      state.cleanData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getComposeSearchData.pending, (state) => {
      state.isFetching = true;
      state.searchLoading = true;
      state.isListAccordionVisible = false;
      state.isListAccordionOpen = false;
    });
    builder.addCase(getComposeSearchData.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isError = false;
      state.isSuccess = true;
      state.searchLoading = false;
      state.errorMessage = "";
      state.searchOriginalData = action.payload;
      state.searchList = getComposeFormattedData(action.payload);
      state.selectedData = {};
      state.isListAccordionVisible = true;
      state.isListAccordionOpen = true;
      // return state;
    });
    builder.addCase(getComposeSearchData.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.searchLoading = false;
      state.isListAccordionVisible = false;
      state.isListAccordionOpen = false;
    });
    builder.addCase(getOutilneFromTopic.pending, (state) => {
      state.outlineLoading = true;
      state.isFetching = true;
      state.isListAccordionOpen = true;
      state.isOutlineAccordionVisible = false;
      state.isOutlineAccordionOpen = false;
    });
    builder.addCase(getOutilneFromTopic.fulfilled, (state, action) => {
      state.outlineLoading = false;
      state.isFetching = false;
      state.isError = false;
      state.isSuccess = true;
      state.errorMessage = "";
      state.topicOutline = action.payload?.Response || "";
      state.isListAccordionOpen = false;
      state.isOutlineAccordionVisible = true;
      state.isOutlineAccordionOpen = true;
      // return state;
    });
    builder.addCase(getOutilneFromTopic.rejected, (state, action) => {
      state.outlineLoading = false;
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.isListAccordionOpen = false;
      state.textFromTopicOutline = action.payload?.Response;
      state.isOutlineAccordionVisible = false;
      state.isOutlineAccordionOpen = false;
    });
    builder.addCase(getTextFromTopicOutline.pending, (state) => {
      state.textLoading = true;
      state.isFetching = true;
      state.isOutlineAccordionOpen = true;
      state.isListAccordionOpen = false;
      state.isWordProcessorVisible = false;
    });
    builder.addCase(getTextFromTopicOutline.fulfilled, (state, action) => {
      state.textLoading = false;
      state.isFetching = false;
      state.isError = false;
      state.isSuccess = true;
      state.errorMessage = "";
      // state.textFromTopicOutline = action.payload?.Response || "";
      state.textFromTopicOutline = action.payload;
      state.isOutlineAccordionOpen = false;
      state.isListAccordionOpen = false;
      state.isWordProcessorVisible = true;
    });
    builder.addCase(getTextFromTopicOutline.rejected, (state, action) => {
      state.textLoading = false;
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.isOutlineAccordionOpen = false;
      state.isListAccordionOpen = false;
      // state.isWordProcessorVisible = false;
      state.isWordProcessorVisible = true;
    });
    builder.addCase(savedocumentfile.pending, (state) => {
      state.savingDocument = true;
      state.isFetching = true;
      state.isOutlineAccordionOpen = true;
      state.isListAccordionOpen = false;
      state.isWordProcessorVisible = false;
    });
    builder.addCase(savedocumentfile.fulfilled, (state, action) => {
      state.savingDocument = false;
      state.isFetching = false;
      state.isError = false;
      state.isSuccess = true;
      state.errorMessage = "";
      state.isOutlineAccordionOpen = false;
      state.isListAccordionOpen = false;
      state.isWordProcessorVisible = true;
      state.documentUrl = action.payload?.Response || "";
      // return state;
    });
    builder.addCase(savedocumentfile.rejected, (state, action) => {
      state.savingDocument = false;
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.isOutlineAccordionOpen = false;
      state.isListAccordionOpen = false;
      state.isWordProcessorVisible = false;
    });
  },
});

// export const composeSliceSelector = (state) => state.compose;

export const {
  updateSelectedData,
  updateTopicOutlineData,
  updateListAccordionOpen,
  updateOutlineAccordionOpen,
  updateWordProcessorVisible,
  closeListAccordion,
  closeOutlineAccordion,
  closeWordProcessor,
  updateScreenDetails,
  updateCleanData
} = ComposeSlice.actions;

export default ComposeSlice.reducer;
