import styles from "./index.module.css";

const DocumentSidebar = ({ tab, setTab, Sections }) => {
  const handleClick = (tabNo) => {
    setTab(tabNo);
  };
  return (
    <div className={styles.tabTrackContainer}>
      <div className={styles.tabTitleContainer}>
        {Sections?.map((item, idx) => (
          <h5
            key={idx}
            className={styles.tabTitle}
            onClick={() => handleClick(idx)}
          >
            {item}
          </h5>
        ))}
      </div>
      <div className={styles.tabTrack}>
        <div
          className={styles.tabThumb}
          style={{ top: tab === 2 ? "75%" : tab === 1 ? "42%" : "8%" }}
        />
      </div>
    </div>
  );
};

export default DocumentSidebar;
