import { useEffect } from "react";
import { useSelector } from "react-redux";

import { UserAuthenticationStatus } from "../redux/slices/auth/authSlice";

const TawkToChat = () => {
  const is_authenticated = useSelector(UserAuthenticationStatus);
  // console.log("is_authenticated", is_authenticated);

  useEffect(() => {
    let script;

    if (is_authenticated) {
      script = document.createElement("script");
      script.async = true;
      script.src = "https://embed.tawk.to/662fc5d11ec1082f04e8cd9b/1hsl9pevi";
      script.charset = "UTF-8";
      script.setAttribute("crossorigin", "*");
      document.body.appendChild(script);
    }

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [is_authenticated]);

  return null;
};

export default TawkToChat;
