import { apiSlice } from "./api/apiSlice";
import composeReducer from "./slices/compose";
import authReducer from "./slices/auth/authSlice";
import generalReducer from "./slices/general/general";
import transformReducer from "./slices/transform/transformSlice";
import quickTextReducer from "./slices/quickText/quickTextSlice";

import { persistReducer, persistStore } from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  timeout: 1400,
  storage: storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  general: generalReducer,
  compose: composeReducer,
  transform:transformReducer,
  quickText: quickTextReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/PAUSE",
          "persist/PURGE",
          "persist/REGISTER",
        ],
      },
    }).concat(apiSlice.middleware),
  // devTools: true,
});

export const persistor = persistStore(store);
