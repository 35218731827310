import * as blobUtil from "blob-util";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useRef, useEffect, useState } from "react";
import {
  Toolbar,
  DocumentEditorContainerComponent,
} from "@syncfusion/ej2-react-documenteditor";

import WarnBanner from "../custom-toast/warn";
import SuccessBanner from "../custom-toast/success";

import { useSaveDocumentsMutation } from "../../../redux/slices/quickText/quickTextApiSlice";
import {
  documentIsNotSaved,
  documentIsSaved,
} from "../../../redux/slices/quickText/quickTextSlice";
import NestedExample from '../../Buttons/nestedButton';
import DropDownButtonComponent from "../../Buttons/nestedButton";
import DropDownButton from "../../Buttons/nestedButton";

DocumentEditorContainerComponent.Inject(Toolbar);

const SyncfusionWordProcessor = ({
                                   data,
                                   text,
                                   userid,
                                   role,
                                   transactionId,
                                 }) => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [link, setLink] = useState("");
  const [openSuccessLink, setOpenSuccessLink] = useState(false);
  const [openErrorLink, setOpenErrorLink] = useState(false);
  const [saveDocuments, { isLoading }] = useSaveDocumentsMutation();
  const [isDocumentEdited, setDocumentEdited] = useState(false); // Initially false
  const [hasBeenSaved, setHasBeenSaved] = useState(false); // Track if the document has been saved at least once

  useEffect(() => {
    if (containerRef.current && data) {
      const documentData = JSON.stringify(data);
      containerRef.current.documentEditor.open(documentData);
      containerRef.current.documentEditor.zoomFactor = 1.25;
      containerRef.current.documentEditor.documentChange = () => {
        setDocumentEdited(true);
      };
    }
  }, [data]);

  const handleSaveDocument = async () => {
    // Check if the document is edited or it's the first save attempt
    if (!hasBeenSaved || isDocumentEdited) {
      let obj = { role, text, userid, transactionId };
      const blob = await containerRef.current.documentEditor.saveAsBlob("Docx");

      const correctBlob =
          blob.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ? blob
              : new Blob([blob], {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              });

      try {
        const base64String = await blobUtil.blobToBase64String(correctBlob);

        obj = { ...obj, fileContentInBase64: base64String };
        const response = await saveDocuments(obj).unwrap();
        if (response && response.Response) {
          setLink(response.Response);
          setOpenErrorLink(false);
          setOpenSuccessLink(true);
          setHasBeenSaved(true); // Mark as saved
          setDocumentEdited(false); // Disable the button after saving
          dispatch(documentIsSaved());
        }
      } catch (error) {
        console.error("Error in processing:", error);
        setOpenErrorLink(true);
        setOpenSuccessLink(false);
        setDocumentEdited(true);
        dispatch(documentIsNotSaved());
        if (error instanceof Error) {
          toast.error("Something went wrong: " + error.message);
        } else {
          toast.error("An unknown error occurred");
        }
      }
    } else {
      // If the document hasn't been edited since the last save, notify the user
      toast.info("Please edit the document before saving again.");
    }
  };
  useEffect(() => {
    handleSaveDocument().then(r => "");
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
      <>
        {openSuccessLink && (
            <SuccessBanner link={link} setOpenSuccessLink={setOpenSuccessLink} />
        )}
        {openErrorLink && <WarnBanner setOpenErrorLink={setOpenErrorLink} />}
        <DocumentEditorContainerComponent
            height={"740px"}
            zoomFactor={2}
            ref={containerRef}
            enableToolbar={true}
            showPropertiesPane={false}
            contentChange={(e) => {
              setDocumentEdited(true);
              dispatch(documentIsNotSaved());
            }}
            serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
        />
        <div className="mt-20 generate-btn-wrapper">
          <button
              className="generate-btn"
              onClick={handleSaveDocument}
              style={{
                cursor:
                    !isDocumentEdited && hasBeenSaved ? "not-allowed" : "pointer",
              }}
              disabled={isLoading || (!isDocumentEdited && hasBeenSaved)}
          >
            {isLoading ? "Saving" : "Save Document"}
          </button>
          {link && <DropDownButton link={link} />}
        </div>
      </>
  );
};

export default SyncfusionWordProcessor;
