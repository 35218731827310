import { useState } from "react";
import { Upload } from "lucide-react";
import Dropzone from "react-dropzone";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../components/shared/loader";
import { selectCurrentUser } from "../../redux/slices/auth/authSlice";
import { generateUniqueId, getCurrentDateTimeUTC } from "../../utils/constant";
import { sendTransformData } from "../../redux/slices/transform/transformSlice";
import GenerateQuiz from "../../components/shared/Transform/To-Quiz/GenerateQuiz";
import DownloaderComponent from "../../components/shared/Transform/DownloaderComponent/DownloaderComponent";
import {
  useVerifyDocumentMutation,
  useVerifyPPTMutation,
} from "../../redux/slices/transform/transformApiSlice";

import styles from "./index.module.css";

const Quiz = () => {
  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);

  const [page, setPage] = useState(0);
  const [fileName, setFileName] = useState("");
  const [explanation, setExplanation] = useState(true);
  const [qestionCount, setQuestionCount] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [questionType, setQuestionType] = useState([]);

  const [base64, setBase64] = useState("");
  const [data, setData] = useState({});
  const [fileExtension, setFileExtension] = useState("");

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [verifyDocument, { isLoading: verifyingDocument }] =
    useVerifyDocumentMutation();
  const [verifyPPT, { isLoading: verifyingPPT }] = useVerifyPPTMutation();

  const onClickNext = async () => {
    if (isSuccess) {
      setPage((prev) => prev + 1);
      return;
    }

    if (base64.length <= 0) {
      return;
    }

    const initialTransactionId = generateUniqueId();
    const obj = {
      userid: userData?.ped_user_id,
      transactionId: initialTransactionId,
      requesttimeinUTC: getCurrentDateTimeUTC(),
      fileName,
      fileContentBase64: base64,
    };

    try {
      let response;
      if (fileExtension === "docx") {
        response = await verifyDocument(obj).unwrap();
      } else if (fileExtension === "pptx") {
        response = await verifyPPT(obj).unwrap();
      }

      if (response) {
        setData(response);
        setIsError(false);
        setIsSuccess(true);
      } else {
        setIsError(true);
        setIsSuccess(false);
        toast.error("Please try again later");
      }
    } catch (error) {
      setIsError(true);
      setIsSuccess(false);
      console.error("Error", error);
      toast.error("Please try again later");
    }
  };

  const generateQuiz = async () => {
    const obj = {
      userid: userData?.ped_user_id,
      transactionId: data.transactionId,
      fileName,
      qestionCount,
      difficulty,
      questionType: questionType.map((item) => item.value).join(", "),
      explanation: explanation ? "y" : "n",
      fileContentBase64: base64,
      requesttimeinUTC: getCurrentDateTimeUTC(),
    };

    setPage(2);
    dispatch(sendTransformData({ type: "doc2Quiz", obj }));
  };

  const handleUpload = async (acceptedFiles) => {
    setIsSuccess(false);
    const filteredFiles = acceptedFiles.filter((file) => {
      const isDocument =
        (file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.presentationml.presentation") &&
        file.size <= 1 * 1024 * 1024;

      return isDocument;
    });

    if (filteredFiles.length > 0) {
      const file = filteredFiles[0];
      setFileName(file.name);
      const extension = file.name.split(".").pop();
      setFileExtension(extension);
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        setBase64(base64String);
      };
      reader.readAsDataURL(file);
    } else {
      setFileName("");
      setFileExtension("");
      toast.error("File size/type does not meet the criteria.");
    }
  };

  const DisplayPage = (pageNo) => {
    switch (pageNo) {
      case 0:
        return (
          <ToQuiz
            handleUpload={handleUpload}
            fileName={fileName}
            isError={isError}
            isSuccess={isSuccess}
          />
        );
      case 1:
        return (
          <GenerateQuiz
            explanation={explanation}
            setExplanation={setExplanation}
            setQuestionCount={setQuestionCount}
            setDifficulty={setDifficulty}
            setQuestionType={setQuestionType}
          />
        );
      case 2:
        return <DownloaderComponent />;
      default:
        return "Page Not Found";
    }
  };

  return (
    <>
      <Helmet>
        <title>Pioneer Ed Tech | Transform | Quiz</title>
      </Helmet>
      <div className={styles.container}>
        {verifyingDocument && <Loader message="Verifying Document" />}
        {verifyingPPT && <Loader message="Verifying PPT" />}
        {page <2 && (
          <h1 className={styles.title}>
            Step into the world of transforming your documents and presentations
            into captivating quizzes. Unveil the potential of converting your
            text into dynamic, interactive learning experiences. With ease,
            upload your materials and witness the seamless creation of engaging
            quizzes
          </h1>
        )}

        <div className={styles.tabContainer}>
          <div className={styles.tabContent}>{DisplayPage(page)}</div>
        </div>
        <div className={styles.nextButtonContainer}>
          {page === 0 && (
            <button onClick={onClickNext} className={styles.nextButton}>
              Next
            </button>
          )}
          {page > 0 && page !== 2 && (
            <button onClick={generateQuiz} className={styles.generateButton}>
              Generate Quiz
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Quiz;

const ToQuiz = ({ handleUpload, fileName, isError, isSuccess }) => {
  const acceptFormats = {
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/pdf": [".pdf"],
    // "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    //   [".pptx"],
  };
  return (
    <div className={styles.innerContainer}>
      <p className={styles.paragraph}>
        To ensure smooth processing, kindly note that files should not exceed 1
        MB in size. Supported file formats for upload include word documents
        (docx) and PDF documents (pdf)
      </p>
      <div>
        <div>
          <Dropzone
            multiple={false}
            accept={acceptFormats}
            onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={styles.inputField}>
                <input {...getInputProps()} />
                <input
                  className={styles.input}
                  type="text"
                  value={fileName}
                  disabled
                />
                <div className={styles.uploadIconContainer}>
                  <Upload className={styles.uploadIcon} />
                </div>
              </div>
            )}
          </Dropzone>
        </div>
        <div className={styles.infoContainer}>
          {isError && (
            <div className={styles.errorText}>
              Sorry for the inconvenience. Please try again later.
            </div>
          )}
          {isSuccess && (
            <div className={styles.successText}>
              The uploaded file matches all criteria for Quiz creation. We shall
              start processing processing now. Please click on the Generate
              button below.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
