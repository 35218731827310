import { Loader } from "lucide-react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

import GeneratedCode from "../generated-code/GeneratedCode";
import logo from "../../../components/shared/images/full_logo.JPEG";

import { UserAuthenticationStatus } from "../../../redux/slices/auth/authSlice";
import { useGetAccessKeyMutation } from "../../../redux/slices/auth/authApiSlice";
import {
  generateUniqueId,
  getCurrentDateTimeUTC,
} from "../../../utils/constant";

import styles from "./index.module.css";

export default function GenerateToken({ userData }) {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [showCode, setShowCode] = useState(false);
  const is_authenticated = useSelector(UserAuthenticationStatus);
  const { handleSubmit } = useForm();

  const [getAccessKey, { isLoading }] = useGetAccessKeyMutation();

  useEffect(() => {
    if (is_authenticated) {
      navigate("/");
    }
  }, [navigate, is_authenticated]);

  const onSubmit = async (data) => {
    const obj = {
      userid: userData?.userid + "",
      transactionId: generateUniqueId(),
      requesttimeinUTC: getCurrentDateTimeUTC(),
    };
    console.log(obj);
    try {
      const response = await getAccessKey(obj).unwrap();
      if (response) {
        setShowCode(true);
        console.log(response);
        setCode(response?.Response);
        // navigate("/login");
        toast.success("Access key generated successfully");
      }
    } catch (error) {
      setShowCode(false);
      console.log(error);
      toast.error("An error occurred please try again later");
    }
  };

  return (
    <>
      {!showCode ? (
        <div className={styles.screen_container}>
          <div className={styles.form_container}>
            <div className={styles.logo_container}>
              <img alt="Logo" src={logo} className={styles.signup_logo} />
              <p className={styles.help} onClick={() => navigate("/login")}>
                Skip to login
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <div className={styles.textContainer}>
                <p className={styles.text}>
                  Your account has been successfully created. Please generate an
                  access key to quickly login in future.
                </p>
                <p className={styles.text}>
                  Your access key is a secret element and do not share it with
                  anyone else. Once access key is generated, It will sent to
                  your registered email address for future reference.
                </p>
                <p className={styles.text}>
                  If you forget your access key, please write us{" "}
                  <span className={styles.text_highlight}>
                    contact@pioneereducationtech.com
                  </span>
                </p>
              </div>

              <div className={styles.form_group_bottom}>
                <div className={`${styles.button_group}`}>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className={`${styles.button} ${styles.outline}`}
                  >
                    {isLoading ? (
                      <Loader
                        className={`${styles.loader_button} ${styles.loader_spin}`}
                      />
                    ) : (
                      "Generate Access Token"
                    )}
                  </button>
                  <p onClick={() => navigate("/login")} className={styles.help}>
                    Already have an account? Log in
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <GeneratedCode code={code} setShowCode={setShowCode} />
      )}
    </>
  );
}
