import { apiSlice } from "../../api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/ped-loginbyemail",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    loginByAccessKey: builder.mutation({
      query: (credentials) => ({
        url: "/ped-loginbyaccesskey",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    verifyEmail: builder.mutation({
      query: (credentials) => ({
        url: "/ped-verifyemail",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    getEmailVerification: builder.mutation({
      query: (credentials) => ({
        url: "/ped-getemailverificationcode",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    getAccessKey: builder.mutation({
      query: (credentials) => ({
        url: "/ped-getaccesskey",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    signUp: builder.mutation({
      query: (credentials) => ({
        url: "/ped-signup",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLoginByAccessKeyMutation,
  useVerifyEmailMutation,
  useGetEmailVerificationMutation,
  useGetAccessKeyMutation,
  useSignUpMutation,
} = authApiSlice;
