import { useState } from "react";
import { Upload } from "lucide-react";
import { Helmet } from "react-helmet";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Loader from "../../components/shared/loader";
import { generateUniqueId, getCurrentDateTimeUTC } from "../../utils/constant";
import { sendTransformData } from "../../redux/slices/transform/transformSlice";
import { useVerifyDocumentMutation } from "../../redux/slices/transform/transformApiSlice";
import GeneratePresentation from "../../components/shared/Transform/To-Presentation/GeneratePresentation";

import styles from "./index.module.css";
import DownloaderComponent from "../../components/shared/Transform/DownloaderComponent/DownloaderComponent";
import { selectCurrentUser } from "../../redux/slices/auth/authSlice";

const Presentation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(selectCurrentUser)

  const [page, setPage] = useState(0);
  const [fileName, setFileName] = useState("");
  const [notes, setNotes] = useState(true);
  const [slideCount, setSlideCount] = useState("");
  const [contentType, setContentType] = useState("");
  const [format, setFormat] = useState("");
  const [base64, setBase64] = useState("");
  const [data, setData] = useState({});

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [fileError, setFileError] = useState(false);

  const [verifyDocument, { isLoading: verifying }] =
    useVerifyDocumentMutation();

  const onClickNext = async () => {
    if (isSuccess) {
      setPage((prev) => prev + 1);
      return;
    }

    if (base64 === "") {
      toast.error("Please upload a document");
      return;
    }

    const initialTransactionId = generateUniqueId();
    const obj = {
      userid: userData?.ped_user_id,
      transactionId: initialTransactionId,
      fileName,
      fileContentBase64: base64,
      requesttimeinUTC: getCurrentDateTimeUTC(),
    };

    try {
      const response = await verifyDocument(obj).unwrap();
      if (response) {
        setData(response);
        setIsError(false);
        setIsSuccess(true);
      } else {
        setIsError(true);
        setIsSuccess(false);
        toast.error("Please try again later");
      }
    } catch (error) {
      setIsError(true);
      setIsSuccess(false);
      console.error("Error", error);
      toast.error("Please try again later");
    }
  };

  const generatePresentation = async () => {
    const obj = {
      userid: userData?.ped_user_id,
      transactionId: data.transactionId,
      fileName,
      slideCount: slideCount,
      contentType,
      format,
      notes: notes ? "y" : "n",
      fileContentBase64: base64,
      requesttimeinUTC: getCurrentDateTimeUTC(),
    };

    // navigate("/notifications");
    setPage((page) => page + 1);
    dispatch(sendTransformData({ type: "doc2PPT", obj }));
  };

  const handleUpload = async (acceptedFiles) => {
    setIsSuccess(false);
    const filteredFiles = acceptedFiles.filter(
      (file) =>
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
        file.size <= 1 * 1024 * 1024
    );

    if (filteredFiles.length > 0) {
      const file = filteredFiles[0];
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        setBase64(base64String);
      };
      reader.readAsDataURL(file);
      setFileError(false);
    } else {
      setFileName("");
      setFileError(true);
      console.log("File size/type does not meet the criteria.");
      // toast.error("File size/type does not meet the criteria.");
    }
  };

  const DisplayPage = (pageNo) => {
    switch (pageNo) {
      case 0:
        return (
          <ToPresentation
            handleUpload={handleUpload}
            fileName={fileName}
            isError={isError}
            isSuccess={isSuccess}
            fileError={fileError}
          />
        );
      case 1:
        return (
          <GeneratePresentation
            notes={notes}
            setNotes={setNotes}
            setSlideCount={setSlideCount}
            setContentType={setContentType}
            setFormat={setFormat}
          />
        );
      case 2:
        return <DownloaderComponent />;
      default:
        return "Page Not Found";
    }
  };

  return (
    <>
      <Helmet>
        <title>Pioneer Ed Tech | Transform | Presentation</title>
      </Helmet>
      <div className={styles.container}>
        {verifying && <Loader message="Verifying Document" />}

        <h1 className={styles.title}>
          Experience the art of transforming your content into engaging
          presentations. Discover the potential of converting text into dynamic
          presentations. Simply upload your documents for a seamless
          presentation transformation.
        </h1>
        <div className={styles.tabContainer}>
          <div className={styles.tabContent}>{DisplayPage(page)}</div>
        </div>
        <div className={styles.nextButtonContainer}>
          {page === 0 && (
            <button onClick={onClickNext} className={styles.nextButton}>
              Next
            </button>
          )}
          {page === 1 && (
            <button
              onClick={generatePresentation}
              className={styles.generateButton}
            >
              Generate Presentation
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Presentation;

const ToPresentation = ({
  handleUpload,
  fileName,
  isError,
  isSuccess,
  fileError,
}) => {
  const acceptFormats = {
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/pdf": [".pdf"],
  };

  return (
    <div className={styles.innerContainer}>
      <p className={styles.paragraph}>
        To ensure smooth processing, kindly note that files should not exceed 1
        MB in size. Supported file formats for upload include Word Documents
        (docx) and PDF Documents (pdf). Let's embark on this creative journey!
      </p>
      <div>
        <div>
          <Dropzone
            multiple={false}
            accept={acceptFormats}
            onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={styles.inputField}>
                <input {...getInputProps()} />
                <input
                  className={styles.input}
                  type="text"
                  value={fileName}
                  disabled
                />
                <div className={styles.uploadIconContainer}>
                  <Upload className={styles.uploadIcon} />
                </div>
              </div>
            )}
          </Dropzone>
        </div>
        <div className={styles.infoContainer}>
          {fileError && (
            <div className={styles.errorText}>
              File size/type does not meet the criteria.
            </div>
          )}
          {isError && (
            <div className={styles.errorText}>
              Something went wrong. Please try again later.
            </div>
          )}
          {isSuccess && (
            <div className={styles.successText}>
              The uploaded file matches all criteria for presentation
              tranformation. We shall start processing now. Please click on the
              Next button below to provide certain configuration information
              details.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
